/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('PagoCreditosDLService', ["$http", function ($http) {
    return {
        getCreditos: function (persona) {
            var obj = {
                "persona": persona,
                "moneda": "DL"
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/cajeroConsultaCreditos", obj);
        },
        getDatos: function (ci) {
            var obj = {
                "ci": ci
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getPersonaci", obj);
        }
    };
}]);

app.controller('PagoCreditosDLCtrl', ['$scope', '$window', 'NgTableParams', 'PagoCreditosDLService', function ($scope, $window, NgTableParams, PagoCreditosDLService) {

        $scope.datosPersona = JSON.parse(localStorage.getItem("datosPersona"));
        //console.log($scope.datosPersona);
        $scope.hayDatos = false;

        $scope.seConsulto = false;



        $scope.cargar = function () {
            console.log("cargar");
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            PagoCreditosDLService.getCreditos($scope.datosPersona.persona)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                        if (dat.estado === 0 && dat.obj !== null && dat.obj.length > 0) {
                            console.log(dat.mensaje);

                            $scope.seConsulto = true;
                            var array = dat.obj;
                            if (dat.obj !== null && dat.obj.length > 0) {
                                $scope.hayDatos = true;
                                array.pop();
                            }
                            $scope.tableParams = new NgTableParams({count: 6}, {dataset: array, counts: []});
                        } else {
                            $scope.hayDatos = false;
                        }
                    })
                    .error(function (err) {
                        console.log(err);
                        $.unblockUI();
                        toastr.error("Ocurrio un error con el servidor, vuelva a intentarlo");
                    });
        };

        try {
            console.log("consultando... " + $scope.datosPersona.persona);
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            $scope.cargar();
        } catch (e) {
            $.unblockUI();
            console.log(e);
        }


        $scope.consultarSocio = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            PagoCreditosDLService.getDatos($scope.cedula)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                        if (dat.obj !== null && dat.obj.length > 0) {
                            $scope.hayDatos = true;
                            localStorage.setItem("haydatos", "true");
                            $scope.persona = dat.obj[0];
                            localStorage.setItem("persona", JSON.stringify($scope.persona));
                            console.log($scope.persona);
                            $scope.datosPersona = localStorage.getItem("persona");
                            $scope.datosPersona = JSON.parse($scope.datosPersona);
                            //console.log($scope.datosPersona);
                            $scope.cargar();
                        }
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                    });
        };



        $scope.inicializar = function () {
            console.log("inicializando");
            $scope.totalSaldoCapital = 0;
            $scope.totalSaldoInteres = 0;
            $scope.totalMontoCuota = 0;
            $scope.totalInteresMoratorio = 0;
            $scope.totalInteresPunitorio = 0;
            $scope.totalDescuento = 0;
            $scope.contador = 0;
            $scope.sumaTotal = 0;
        };

        $scope.inicializar();

        $scope.contador = 0;

        $scope.checkbox = function (credito) {
            if (credito.check) {
                $scope.contador++;
//                console.log($scope.contador);
                $scope.totalSaldoCapital = $scope.totalSaldoCapital + credito.saldo_capital;
                $scope.totalSaldoInteres = $scope.totalSaldoInteres + credito.saldo_intereses;
                $scope.totalMontoCuota = $scope.totalMontoCuota + credito.monto_cuota;
                $scope.totalInteresMoratorio = $scope.totalInteresMoratorio + credito.c_intereses_moratorios;
                $scope.totalInteresPunitorio = $scope.totalInteresPunitorio + credito.c_intereses_punitorios;
                $scope.totalDescuento = $scope.totalDescuento + credito.c_descuento;
                credito.total = credito.monto_cuota + credito.c_intereses_moratorios + credito.c_intereses_punitorios - credito.c_descuento;
                $scope.sumaTotal = $scope.sumaTotal +credito.total;
                if ($scope.contador <= 0) {
                    $scope.inicializar();
                }
            } else {
//                console.log(credito.check);
//                console.log(false);
                $scope.contador--;
//                console.log($scope.contador);
                $scope.totalSaldoCapital = $scope.totalSaldoCapital - credito.saldo_capital;
                $scope.totalSaldoInteres = $scope.totalSaldoInteres - credito.saldo_intereses;
                $scope.totalMontoCuota = $scope.totalMontoCuota - credito.saldo_intereses;
                $scope.totalInteresMoratorio = $scope.totalInteresMoratorio - credito.c_intereses_moratorios;
                $scope.totalInteresPunitorio = $scope.totalInteresPunitorio - credito.c_intereses_punitorios;
                $scope.totalDescuento = $scope.totalDescuento - credito.c_descuento;
                credito.total = credito.monto_cuota + credito.c_intereses_moratorios + credito.c_intereses_punitorios - credito.c_descuento;
                $scope.sumaTotal = $scope.sumaTotal - credito.total;
                credito.total = null;
                if ($scope.contador <= 0) {
                    $scope.inicializar();
                }
            }
//            console.log($scope.totalSaldoCapital);
//            console.log($scope.totalSaldoInteres);

        };
        
        $scope.pagar = function(){
            if ($scope.sumaTotal === 0 ){
                toastr.warning("El monto a pagar es 0");
            } else{
                toastr.info("pagando "+$scope.sumaTotal);  
            }
          
        };



    }]);