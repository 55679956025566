var app = angular.module('CajaMedica');

app.controller('AporteUs2Ctrl', ['$scope', '$window', 'NgTableParams', 'AporteUS2Factory', '$timeout', function ($scope, $window, NgTableParams, AporteUS2Factory, $timeout, blockUI) {


        $('#datetimepicker1').datetimepicker({
            format: 'DD/MM/YYYY',
           // locale: 'es'
        });

        $('#datetimepicker2').datetimepicker({
            format: 'DD/MM/YYYY',
             // locale: 'es'
        });

        $scope.datosPersona = localStorage.getItem("datosPersona");
        $scope.datosPersona = JSON.parse($scope.datosPersona);
        console.log($scope.datosPersona);

        $scope.guarani = true;
        $scope.dolar = false;
        $scope.hayGuaranies = true;
        $scope.hayDolares = false;
        var count = 0;


        $scope.getAportesGS = function () {
            AporteUS2Factory.getAportesGS($scope.datosPersona.persona)
                    .success(function (dat) {
                        count++;
                        if (count >= 2) {
                            $.unblockUI();
                        }
                        console.log(dat);
                        if (dat.estado === 0) {
                            if (dat.obj !== null && dat.obj.length > 0 && dat.obj[0].aporteAcumulado !== '0.00' && dat.obj[0].total !== '0.00') {
                                console.log(dat.mensaje);
                                $scope.tableParamsGS = new NgTableParams({count: 5}, {dataset: dat.obj, counts: []});
                                $scope.hayGuaranies = true;
                            }

                        } else {
                            $scope.error = true;
                        }
                    })
                    .error(function (err) {
                        toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                        console.log(err);
                        count++;
                        if (count >= 2) {
                            $.unblockUI();
                        }
                    });
        };
        $scope.getAportesUSD = function () {
            AporteUS2Factory.getAportesUSD($scope.datosPersona.persona)
                    .success(function (dat) {
                        count++;
                        if (count >= 2) {
                            $.unblockUI();
                        }
                        console.log(dat);
                        if (dat.estado === 0) {
                            if (dat.obj !== null && dat.obj.length > 0 && dat.obj[0].aporteAcumulado !== '0.00' && dat.obj[0].total !== '0.00') {
                                console.log(dat.mensaje);
                                $scope.tableParamsUSD = new NgTableParams({count: 5}, {dataset: dat.obj, counts: []});
                                $scope.hayDolares = true;
                            }
                        } else {
                            $scope.error = true;
                        }
                    })
                    .error(function (err) {
                        toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                        console.log(err);
                        count++;
                        if (count >= 2) {
                            $.unblockUI();
                        }
                    });
        };
        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
        $scope.getAportesGS();
        $scope.getAportesUSD();

        $scope.clickGS = function () {
            $scope.guarani = true;
            $scope.dolar = false;
        };
        $scope.clickDL = function () {
            $scope.guarani = false;
            $scope.dolar = true;
        };

        $scope.fechaInicio;
        $scope.fechaFin;
        
        
        $scope.ordenFecha = 0;
        $scope.orderByFecha = function () {
            $scope.ordenFechaRendicion = 0;
            $scope.ordenFechaVencimiento = 0;
            if ($scope.ordenFecha === 2) {
                $scope.ordenFecha = 1;
                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {fecha: "asc"}}, {dataset: $scope.listaAportes, counts: []});

            } else {
                $scope.ordenFecha = 2;
                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {fecha: "desc"}}, {dataset: $scope.listaAportes, counts: []});
            }
        };
        $scope.ordenFechaRendicion = 0;
        $scope.orderByFechaRendicion = function () {
            $scope.ordenFecha = 0;
            $scope.ordenFechaVencimiento = 0;
            if ($scope.ordenFechaRendicion === 2) {
                $scope.ordenFechaRendicion = 1;
                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {fechaPlanilla: "asc"}}, {dataset: $scope.listaAportes, counts: []});

            } else {
                $scope.ordenFechaRendicion = 2;
                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {fechaPlanilla: "desc"}}, {dataset: $scope.listaAportes, counts: []});
            }
        };
        $scope.ordenFechaVencimiento = 0;
        $scope.orderByFechaVencimiento = function () {
            $scope.ordenFecha = 0;
            $scope.ordenFechaRendicion = 0;
            if ($scope.ordenFechaVencimiento === 2) {
                $scope.ordenFechaVencimiento = 1;
                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {vencimiento: "asc"}}, {dataset: $scope.listaAportes, counts: []});

            } else {
                $scope.ordenFechaVencimiento = 2;
                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {vencimiento: "desc"}}, {dataset: $scope.listaAportes, counts: []});
            }
        };
        
        $scope.verDetalles2 = function () {
            $('#consultarAporte').modal('hide');
            //            toastr.info("fechas: " + $scope.fechaInicio + "    " + $scope.fechaFin);
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            if (false) {
                $scope.fechaInicio = $('#idFechaInicio').val();
                $scope.fechaFin = $('#idFechaFin').val();
                AporteUS2Factory.getDetalleAporteGS($scope.datosPersona.persona, $scope.fechaInicio, $scope.fechaFin)
                        .success(function (dat) {
                            $.unblockUI();
                            console.log(dat);
                            if (dat.obj !== null && dat.obj.length > 0) {
                                
                                $scope.ordenFecha = 1;

                                //$scope.tableDetalles = new NgTableParams({ count: 10 }, { dataset: dat.obj, counts: [] });
                                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {fecha: "asc"}}, {dataset: dat.obj, counts: []});
                                $timeout(function () {
                                    $('#detalles2').modal('show');
                                }, 200);
                            } else {
                                toastr.warning("No se encontraron datos de aportes en Guaranies");
                            }

                        })
                        .error(function (err) {
                            $.unblockUI();
                            toastr.error("Ocurrio un error con el servidor. Intentelo de nuevo");
                            console.log(err);
                        });
            }
            if (true) {
                $scope.fechaInicio = $('#idFechaInicio').val();
                $scope.fechaFin = $('#idFechaFin').val();
                AporteUS2Factory.getDetalleAporteUSD($scope.datosPersona.persona, $scope.fechaInicio, $scope.fechaFin)
                        .success(function (dat) {
                            $.unblockUI();
                            console.log(dat);
                            if (dat.obj !== null && dat.obj.length > 0) {
                                $scope.listaAportes = dat.obj;

                                //$scope.tableDetalles = new NgTableParams({ count: 10 }, { dataset: dat.obj, counts: [] });
                                $scope.tableParamsDetalles2 = new NgTableParams({count: 1000, sorting: {cuota: "asc"}}, {dataset: dat.obj, counts: []});
                                $timeout(function () {
                                    $('#detalles2').modal('show');
                                }, 200);
                            } else {
                                toastr.warning("No se encontraron datos de aportes en Dolares");
                            }

                        })
                        .error(function (err) {
                            $.unblockUI();
                            toastr.error("Ocurrio un error con el servidor. Intentelo de nuevo");
                            console.log(err);
                        });
            }

        };

        $scope.generarPDf = function () {

            $scope.datosPersona = localStorage.getItem("datosPersona");
            $scope.datosPersona = JSON.parse($scope.datosPersona);
//        var lista []
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            AporteUS2Factory.GeneraPdf($scope.datosPersona, $scope.listaAportes)
                    .success(function (data) {
                        console.log(data);
                        $.unblockUI();
                        window.location.replace("/SwitchCajaMedica-web/ReporteAporteUs");
                        $scope.blockui = false;
                    })
                    .error(function (error) {
                        console.log(error);
                    });

        };



    }]);
