app.factory("ComprobantesService", ["$http", function ($http) {
    return {
        listarFacturas: function (params) {
            return $http.get("/SwitchCajaMedica-web/resource/services/factura/" + params);
        },
        listarRecibos: function (params) {
            return $http.get("/SwitchCajaMedica-web/resource/services/recibo/" + params);
        },
        imprimirFactura: function (movto, persona) {
            return $http({
                method: 'GET',
                url: "/SwitchCajaMedica-web/resource/services/reporte-factura/" + movto + "/" + persona,
                responseType: 'arraybuffer'
            });
        },
        imprimirRecibo: function (movto, digital, persona, tipo) {
            return $http({
                method: 'GET',
                url: "/SwitchCajaMedica-web/resource/services/reporte-recibo/" + movto + "/" + digital + "/" + persona + "/" + tipo,
                responseType: 'arraybuffer'
            });
        }
    }
}]);

/*
var mapaFactura = { 
    facturaContado = "0", 
    existeTasaVariable = "S", 
    vencTimbrado = "2020 - 05 - 31", 
    numeroDocto = "723467", 
    totalIva5Dl = "0.00", 
    cobrosVarios = "0", 
    origen = "R", 
    doctoIdentidad = "C.I.: 379199", 
    impresion = "REIMPRESI“N", 
    nroDoctoOrigen = "58050", 
    iniTimbrado = "2019 - 05 - 28", 
    serieFactura = "5", 
    movtosPersona = "311", 
    timbrado = "13427855", 
    totalIva10Gs = "0.00", 
    totalExentoDl = "0.00", 
    telefono = "Telef.: 0528 - 222496", 
    coidgoPersona = "311", 
    vueltoDolares = "0.00", 
    apellidosYNombres = "ZARATE GUERRERO, JORGE DERLIS", 
    cambio = "6530.00", 
    cobrosDeNoAportes = "1", 
    porcentajeIva = "0", 
    direccion = "Direc.: Juan E.O'Leary 222, Vicepresidencia de la republica", 
    numeroFactura="10080006208", 
    fecha="2020 - 03 - 11", 
    fechaRecibo="2020 - 05 - 11 00: 00: 00.0", 
    totalExentoGs="3642505.00", 
    vueltoGuaranies="0.00", 
    totalIva5Gs="0.00", 
    totalIva10Dl="0.00", 
    movto="987642" 
};

    var mapaDetalles = [
        {
            descripcion=CREDITO, 
            cuota="5", 
            comision_cobrador="0.00", 
            fecha_vencimiento="2020-03-10", 
            intereses_punitorios="0.00", 
            intereses="3642505.00", 
            v_concepto="Inter©s Cuota" , 
            monto="3033715.00", 
            impuesto="0.00", 
            v_item="1", 
            iva="0", 
            concepto="6", 
            moneda="GS", 
            numero_docto="14024", 
            total_descuentos="0.00", 
            cant_cuotas="72", 
            intereses_moratorios="0.00"
        }
    ];
*/