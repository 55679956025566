/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.controller('InicioCtrl', ['$scope', '$window', 'NgTableParams', '$timeout', 'InicioFactory', 'TarjetasCreditosFactory', function ($scope, $window, NgTableParams, $timeout, InicioFactory, TarjetasCreditosFactory) {

	var ctx = document.getElementById("myChart");
	var myChart = new Chart(ctx, {
		type: 'bar',
		data: {
			labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
			datasets: [{
				label: '# of Votes',
				data: [12, 19, 3, 5, 2, 3],
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)'
				],
				borderColor: [
					'rgba(255,99,132,1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)'
				],
				borderWidth: 1
			}]
		},
		options: {
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true
					}
				}]
			},
			responsive: true
		}
	});

	$scope.mostrarGrafico = function () {
		$('#graficoModal').modal('show');
	};

	$scope.datosPersona = JSON.parse(localStorage.getItem("datosPersona"));

	$scope.getDatosInicio = function () {
		$.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
		var count = 0;
		$scope.hayAportesGS = false;
		InicioFactory.getAportesGS($scope.datosPersona.persona)
			.success(function (dat) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				if (dat.obj !== null && dat.obj.length > 0) {
					$scope.aporteGS = dat.obj[0];
					$scope.hayAportesGS = true;
				}
			})
			.error(function (err) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				console.log(err);
			});
		$scope.hayAportesDL = false;
		InicioFactory.getAportesUSD($scope.datosPersona.persona)
			.success(function (dat) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				if (dat.obj !== null && dat.obj.length > 0) {
					$scope.aporteDL = dat.obj[0];
					$scope.hayAportesDL = true;
				}
			})
			.error(function (err) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				console.log(err);
			});
		InicioFactory.getTarjetas($scope.datosPersona.persona)
			.success(function (dat) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				$scope.tarjetas = dat.obj;
			})
			.error(function (err) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				console.log(err);
			});
		InicioFactory.getCreditos($scope.datosPersona.persona)
			.success(function (dat) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				var array = dat.obj;
				array.pop();
				$scope.creditos = array;
			})
			.error(function (err) {
				count++;
				if (count >= 4) {
					$.unblockUI();
				}
				console.log(err);
			});
	};

	$scope.getDatosInicio();



	$scope.total1 = function () {
		console.log('total1');
		$scope.datosPersona.total1 = $scope.datosPersona.sueldo + $scope.datosPersona.honorarios + $scope.datosPersona.ingreso_conyuge + $scope.datosPersona.ingresos_otros;
		console.log($scope.datosPersona.total1);
	};
	$scope.total2 = function () {
		console.log('total2');
		$scope.datosPersona.total2 = $scope.datosPersona.alquiler + $scope.datosPersona.otracuota + $scope.datosPersona.tarjeta + $scope.datosPersona.gastos;
		console.log($scope.datosPersona.total2);
	};
	$scope.enviarSolicitudTarjeta = function () {
		console.log("enviando solicitud");
		$.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
		$('#solicitudtc').modal('hide');
		console.log($scope.datosPersona);
		InicioFactory.enviarSolicitud($scope.datosPersona)
			.success(function (data) {
				$.unblockUI();
				console.log(data);
				if (data.estado === 0) {
					toastr.info('Solicitud enviada con exito');

				} else {
					toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
				}

			})
			.error(function (err) {
				$.unblockUI();
				console.log(err);

			});
	};

	$scope.showMessage = false;

	$scope.enviarSolicitudCredito = function () {
		$scope.showMessage = false;

		/* Rellenamos correctamente los campos faltantes */
		$scope.datosPersona.profesion = $scope.profesion[0].profesion;
		$scope.datosPersona.nacionalidad = $scope.nacionalidad[0].pais;
		$scope.datosPersona.barrio_residencia = $scope.barrio[0].barrio;
		$scope.datosPersona.ciudad_residencia = $scope.ciudad[0].ciudad;

		if ($scope.destino[0]) {
			$scope.datosPersona.destino = $scope.destino[0].destino;
		}
		
		if ($scope.asesor[0]) {
			$scope.datosPersona.asesor = $scope.asesor[0].persona;
		} else {
			$scope.showMessage = true;
			return;
		}

		$('#solicitudcredito').modal('hide');
		
		/* Enviamos los primeros datos del vector de campos duplicados */
		/*  Inmuebles */
		$scope.datosPersona.ubicacion_inmueble =
			$scope.datosPersona.inmuebles[0].ubicacion_inmueble;
		$scope.datosPersona.finca = $scope.datosPersona.inmuebles[0].finca;
		$scope.datosPersona.cta_catastral =
			$scope.datosPersona.inmuebles[0].cta_catastral;
		$scope.datosPersona.distrito = $scope.datosPersona.inmuebles[0].distrito;
		$scope.datosPersona.tieneEscritura =
			$scope.datosPersona.inmuebles[0].tieneEscritura;
		$scope.datosPersona.valor_inmueble =
			$scope.datosPersona.inmuebles[0].valor_inmueble;
		$scope.datosPersona.p_posee_inmueble =
			$scope.datosPersona.inmuebles[0].p_posee_inmueble;
		/*  Vehiculos */
		$scope.datosPersona.vehiculo_valor =
			$scope.datosPersona.vehiculos[0].vehiculo_valor;
		$scope.datosPersona.vehiculo_monto_cuota =
			$scope.datosPersona.vehiculos[0].vehiculo_monto_cuota;
		$scope.datosPersona.vehiculo_saldo =
			$scope.datosPersona.vehiculos[0].vehiculo_saldo;
		$scope.datosPersona.vehiculo_marca =
			$scope.datosPersona.vehiculos[0].vehiculo_marca;
		$scope.datosPersona.vehiculo_modelo =
			$scope.datosPersona.vehiculos[0].vehiculo_modelo;
		/*  Referencias */

		$scope.datosPersona.referencia_personal =
			$scope.datosPersona.referencias[0].referencia_personal;
		$scope.datosPersona.telefono_referencia =
			$scope.datosPersona.referencias[0].telefono_referencia;

		/*  Datos Laborales */

		console.log('Datos de la persona =', $scope.datosPersona);
		
		$.blockUI({
			css: { backgroundColor: 'transparent', color: '#fff', border: 'none' },
			message: '<img src="static/imagenes/ellipsis.gif" />'
		});
		
		InicioFactory.enviarSolicitudCredito($scope.datosPersona)
		  .success(function (data) {
			//$.unblockUI();
			console.log(data);
			if (data.estado === 0) {
			  toastr.info('Solicitud enviada con exito');
  
			} else {
			  toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
			}
  
		  })
		  .error(function (err) {
			$.unblockUI();
			console.log(err);
  
		  });
		  
	};

	$scope.solicitudTC = function () {
		$.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
		var count = 0;
		$scope.datosPersona.nroPersonas = 0;
		$scope.datosPersona.cant_propiedades = 0;
		$scope.datosPersona.tienePropiedad = 'S';
		$scope.datosPersona.poseevehiculo = 'S';
		$scope.datosPersona.poseetarjetas = 'S';
		$scope.datosPersona.cant_tarjetas = 0;
		$scope.datosPersona.sueldo = 0;
		$scope.datosPersona.honorarios = 0;
		$scope.datosPersona.ingreso_conyuge = 0;
		$scope.datosPersona.ingresos_otros = 0;
		$scope.datosPersona.alquiler = 0;
		$scope.datosPersona.otracuota = 0;
		$scope.datosPersona.tarjeta = 0;
		$scope.datosPersona.gastos = 0;
		$scope.datosPersona.tieneEscrituras = 'N';
		$scope.datosPersona.menores_a_cargo = 0;
		$scope.datosPersona.mayores_a_cargo = 0;
		InicioFactory.getProfesiones()
			.success(function (dat) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				$scope.profesiones = dat.obj.profesiones;
				console.log("profesiones");
				console.log($scope.profesiones);
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getAsesores()
			.success(function (dat) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getPaises()
			.success(function (dat) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				$scope.nacionalidades = dat.obj.paises;
				console.log("naiconalidades");
				console.log($scope.nacionalidades);
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getCiudades()
			.success(function (dat) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				$scope.ciudades = dat.obj.ciudades;
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getBarrios()
			.success(function (dat) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				$scope.barrios = dat.obj.barrios;
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});
		InicioFactory.especialidades()
			.success(function (dat) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});

		InicioFactory.getTelefonos($scope.datosPersona.persona)
			.success(function (data) {
				console.log(data);
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				if (data.estado === 0) {
					var array = data.obj;
					array.forEach(function (item, i) {
						if (item.tipo === 'TE') {
							$scope.datosPersona.telefono = item.numero;
						} else if (item.tipo === 'CE') {
							$scope.datosPersona.celular = item.numero;
						}
					});
				}
				console.log(data);
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});

		InicioFactory.getTrabajos($scope.datosPersona.persona)
			.success(function (data) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(data);
				if (data.estado === 0 && data.obj !== null) {
					var trabajo = data.obj[0];
					$scope.datosPersona.lugar_laboral = trabajo.lugar_laboral;
					$scope.datosPersona.telefono_laboral = trabajo.telefonos;
					$scope.datosPersona.direccion_laboral = trabajo.direccion;
					$scope.datosPersona.cargo = trabajo.cargo;
					$scope.datosPersona.fecha_ingreso = trabajo.fecha_ingreso;
					console.log($scope.datosPersona.telefono_laboral);

				}
			})
			.error(function (err) {
				count += 1;
				if (count >= 6) {
					$.unblockUI();
					$('#solicitudtc').modal('show');
				}
				console.log(err);
			});
	};
	$scope.solicitudCredito = function () {
		$.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
		var count = 0;
		$scope.datosPersona.nroPersonas = 0;
		$scope.datosPersona.cant_propiedades = 0;
		$scope.datosPersona.tienePropiedad = 'S';
		$scope.datosPersona.poseevehiculo = 'S';
		$scope.datosPersona.poseetarjetas = 'S';
		$scope.datosPersona.cant_tarjetas = 0;
		$scope.datosPersona.sueldo = 0;
		$scope.datosPersona.honorarios = 0;
		$scope.datosPersona.ingreso_conyuge = 0;
		$scope.datosPersona.ingresos_otros = 0;
		$scope.datosPersona.alquiler = 0;
		$scope.datosPersona.otracuota = 0;
		$scope.datosPersona.tarjeta = 0;
		$scope.datosPersona.gastos = 0;
		$scope.datosPersona.tieneEscrituras = 'N';
		$scope.datosPersona.menores_a_cargo = 0;
		$scope.datosPersona.mayores_a_cargo = 0;
		$scope.datosPersona.valor_inmueble = 0;
		$scope.datosPersona.vehiculo_valor = 0;
		$scope.datosPersona.vehiculo_monto_cuota = 0;
		$scope.datosPersona.vehiculo_saldo = 0;

		/* Campos duplicables */
		$scope.disableBtn = false;

		/* Vehiculos */
		var data_vehiculo = {};
		$scope.indiceVehiculos = 0;
		data_vehiculo.vehiculo_valor = 0;
		data_vehiculo.vehiculo_monto_cuota = 0;
		data_vehiculo.vehiculo_saldo = 0;
		data_vehiculo.vehiculo_marca = '';
		data_vehiculo.vehiculo_modelo = '';

		$scope.datosPersona.vehiculos = [];
		$scope.datosPersona.vehiculos.push(data_vehiculo);

		$scope.agregarVehiculo = function () {
			var dat = {};
			dat.vehiculo_valor = 0;
			dat.vehiculo_monto_cuota = 0;
			dat.vehiculo_saldo = 0;
			dat.vehiculo_marca = '';
			dat.vehiculo_modelo = '';
			$scope.datosPersona.vehiculos.push(dat);
		};
		/* Inmuebles*/
		var data_inmueble = {};
		data_inmueble.ubicacion_inmueble = '';
		data_inmueble.finca = '';
		data_inmueble.cta_catastral = '';
		data_inmueble.distrito = '';
		data_inmueble.tieneEscritura = '';
		data_inmueble.valor_inmueble = 0;
		data_inmueble.p_posee_inmueble = 'N';

		$scope.datosPersona.inmuebles = [];
		$scope.datosPersona.inmuebles.push(data_inmueble);

		$scope.agregarInmueble = function () {
			var dat = {};
			dat.ubicacion_inmueble = '';
			dat.finca = '';
			dat.cta_catastral = '';
			dat.distrito = '';
			dat.tieneEscritura = '';
			dat.valor_inmueble = 0;
			dat.p_posee_inmueble = '';
			$scope.datosPersona.inmuebles.push(dat);
			console.log($scope.datosPersona.inmuebles);
		};

		/* Referencias Personales */
		var dataReferencia = {};

		dataReferencia.referencia_personal = '';
		dataReferencia.telefono_referencia = '';

		$scope.datosPersona.referencias = [];
		$scope.datosPersona.referencias.push(dataReferencia);

		$scope.agregarReferencia = function () {
			var dat = {};
			dat.referencias_personal = '';
			dat.telefono_referencia = '';
			$scope.datosPersona.referencias.push(dat);
		};
		//Bloqueo de ui

		InicioFactory.getProfesiones()
			.success(function (dat) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				$scope.profesiones = dat.obj.profesiones;

				/* Dropdown multiselect .. */

				$scope.profesion = []; //datosPersona.profesion
				$scope.profesionesData = [];
				$scope.profesiones.forEach(function (value, i) {
					var profesionesOptions = {
						id: i,
						label: value.descripcion,
						profesion: value.profesion
					};

					$scope.profesionesData.push(profesionesOptions);
					if (value.profesion == $scope.datosPersona.profesion) {
						$scope.profesion = [$scope.profesionesData[i]];
					}
				});

				//Cargar predeterminadamente con la profesion de la persona
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getAsesores()
			.success(function (dat) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				$scope.asesores = dat.obj;

				/* Dropdown multiselect .. */

				$scope.asesor = []; //datosPersona.asesor
				$scope.asesoresData = [];
				$scope.asesores.forEach(function (value, i) {
					var asesoresOptions = {
						id: i,
						label: value.asesor,
						persona: value.persona,
						mail: value.mail
					};

					$scope.asesoresData.push(asesoresOptions);
				});
				console.log($scope.asesoresData);
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getPaises()
			.success(function (dat) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}

				$scope.nacionalidades = dat.obj.paises;

				/* Dropdown multiselect .. */

				$scope.nacionalidad = []; //datosPersona.nacionalidad
				$scope.nacionalidadesData = [];
				$scope.nacionalidades.forEach(function (value, i) {
					var nacionalidadesOptions = {
						id: i,
						label: value.nacionalidad,
						pais: value.pais,
						nombre: value.nombre
					};
					$scope.nacionalidadesData.push(nacionalidadesOptions);
					if (value.pais == $scope.datosPersona.nacionalidad) {
						$scope.nacionalidad = [$scope.nacionalidadesData[i]];
					}
				});
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getCiudades()
			.success(function (dat) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				$scope.ciudades = dat.obj.ciudades;

				/* Dropdown multiselect .. */

				$scope.ciudad = []; //datosPersona.ciudad
				$scope.ciudadesData = [];
				$scope.ciudades.forEach(function (value, i) {
					var ciudadesOptions = {
						id: i,
						label: value.descripcion,
						ciudad: value.ciudad,
						pais: value.pais
					};
					$scope.ciudadesData.push(ciudadesOptions);
					if (value.ciudad == $scope.datosPersona.ciudad_residencia) {
						$scope.ciudad = [$scope.ciudadesData[i]];
					}
				});
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getBarrios()
			.success(function (dat) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				$scope.barrios = dat.obj.barrios;

				/* Dropdown multiselect .. */

				$scope.barrio = []; //datosPersona.barrio
				$scope.barriosData = [];
				$scope.barrios.forEach(function (value, i) {
					var barriosOptions = {
						id: i,
						label: value.descripcion,
						barrio: value.barrio
					};
					//Valor predeterminado
					$scope.barriosData.push(barriosOptions);
					if (value.barrio == $scope.datosPersona.barrio_residencia) {
						$scope.barrio = [$scope.barriosData[i]];
					}
				});
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});
		InicioFactory.getDestinos()
			.success(function (dat) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				$scope.destinos = dat.obj;

				/* Dropdown multiselect .. */

				$scope.destino = []; //datosPersona.destino
				$scope.destinosData = [];
				$scope.destinos.forEach(function (value, i) {
					var destinosOptions = {
						id: i,
						label: value.descripcion,
						destino: value.destino
					};

					$scope.destinosData.push(destinosOptions);
				});
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});
		InicioFactory.especialidades()
			.success(function (dat) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}

			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});

		InicioFactory.getTelefonos($scope.datosPersona.persona)
			.success(function (data) {

				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				if (data.estado === 0) {
					var array = data.obj;
					array.forEach(function (item, i) {
						if (item.tipo === 'TE') {
							$scope.datosPersona.telefono = item.numero;
						} else if (item.tipo === 'CE') {
							$scope.datosPersona.celular = item.numero;
						}
					});
				}
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});

		InicioFactory.getTrabajos($scope.datosPersona.persona)
			.success(function (data) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}

				if (data.estado === 0 && data.obj !== null) {
					var trabajo = data.obj[0];
					$scope.datosPersona.lugar_laboral = trabajo.lugar_laboral;
					$scope.datosPersona.telefono_laboral = trabajo.telefonos;
					$scope.datosPersona.direccion_laboral = trabajo.direccion;
					$scope.datosPersona.cargo = trabajo.cargo;
					$scope.datosPersona.fecha_ingreso = trabajo.fecha_ingreso;

					dataTrabajo.lugar_laboral = trabajo.lugar_laboral;
					dataTrabajo.telefono_laboral = trabajo.telefonos;
					dataTrabajo.direccion_laboral = trabajo.direccion;
					dataTrabajo.cargo = trabajo.cargo;
					dataTrabajo.fecha_ingreso_laboral = trabajo.fecha_ingreso;

					$scope.datosPersona.trabajos = [];
					$scope.datosPersona.trabajos.push(dataTrabajo);
				}
			})
			.error(function (err) {
				count += 1;
				if (count >= 9) {
					$.unblockUI();
					$('#solicitudcredito').modal('show');
				}
				console.log(err);
			});
		/* Datos laborales */
		var dataTrabajo = {};

		$scope.agregarTrabajo = function () {
			var dat = {};
			dat.lugar_laboral = '';
			dat.telefono_laboral = '';
			dat.direccion_laboral = '';
			dat.cargo = '';
			dat.fecha_ingreso_laboral = '';
			$scope.datosPersona.trabajos.push(dat);
		};
		$scope.adjunto = [];
		//Cargar archivos
		$scope.filesSelected = function ($files) {
			$scope.adjunto = [];
			var i = 0;
			$file = $files[i];
			while ($file !== null && typeof $file != 'undefined') {
				$scope.adjunto.push($file);
				i++;
				$file = $files[i];
			}
		};
		$scope.eliminarDoc = function (doc, i) {
			$scope.adjunto.splice(i, 1);
		};
	};

	$scope.irCreditos = function () {
		$window.location.href = "#/app/creditos";
	};
	$scope.irTarjetas = function () {
		$window.location.href = "#/app/tarjetasCredito";
	};
	$scope.irAportes = function () {
		$window.location.href = "#/app/aportes";
	};
	$scope.irAportesUS = function () {
		$window.location.href = "#/app/aportesus";
	};


	$scope.hayDatos = false;
	TarjetasCreditosFactory.getTarjetasCredito($scope.datosPersona.persona)
		.success(function (dat) {
			$.unblockUI();
			if (dat.estado === 0) {
				var array = dat.obj;
				//                        array.pop();
				$scope.tableParamsTC = new NgTableParams({ count: 5 }, { dataset: array, counts: [] });
				$scope.hayDatos = true;
			} else {
				$scope.error = true;
			}

		})
		.error(function (err) {
			console.log(err);
			$.unblockUI();
			toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
		});
	//Opciones y traducciones para cada multiselect
	$scope.multiSelectSettings = {
		enableSearch: true,
		dynamicTitle: true,
		showCheckAll: false,
		showUncheckAll: false,
		selectionLimit: 1,
		scrollable: true,
		buttonClasses: 'btn btn-default btnMulti',
		closeOnSelect: true,
		//Para que al seleccionar un elemento, se cambie el texto en la caja, por defecto apunta a 'label'
		smartButtonMaxItems: 1,
		smartButtonTextConverter: function (itemText, originalItem) {
			return itemText;
		}
	};
	$scope.profesionesTraducciones = {
		searchPlaceholder: 'Buscar:',
		buttonDefaultText: 'Seleccione su profesión'
	};
	$scope.nacionalidadesTraducciones = {
		searchPlaceholder: 'Buscar:',
		dynamicButtonTextSuffix: 'nacionalidad seleccionada',
		buttonDefaultText: 'Seleccione su nacionalidad'
	};
	$scope.ciudadesTraducciones = {
		searchPlaceholder: 'Buscar:',
		dynamicButtonTextSuffix: 'ciudad seleccionada',
		buttonDefaultText: 'Seleccione su ciudad'
	};
	$scope.barriosTraducciones = {
		searchPlaceholder: 'Buscar:',
		dynamicButtonTextSuffix: 'barrio seleccionado',
		buttonDefaultText: 'Seleccione su barrio'
	};
	$scope.asesoresTraducciones = {
		searchPlaceholder: 'Buscar:',
		dynamicButtonTextSuffix: 'asesor seleccionado',
		buttonDefaultText: 'Seleccione su asesor'
	};
	$scope.destinosTraducciones = {
		searchPlaceholder: 'Buscar:',
		dynamicButtonTextSuffix: 'destino seleccionado',
		buttonDefaultText: 'Seleccione el destino'
	};
}
]);
