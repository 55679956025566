/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('TarjetasFactory', ["$http", function ($http) {
    return {
        getTarjetas: function (persona) {
            var obj = {
                "persona": persona
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/historialTarjeta", obj);
        }
    };
}]);

app.controller('TarjetasCtrl', ['$scope', '$window', 'NgTableParams', 'TarjetasFactory', function ($scope, $window, NgTableParams, TarjetasFactory,blockUI) {
        console.log('TarjetasCtrl');
        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
//        $window.alert('BlockUI has finished.'); 
        $scope.datosPersona = localStorage.getItem("datosPersona");
        $scope.datosPersona = JSON.parse($scope.datosPersona);
        //console.log($scope.datosPersona);
        $scope.hayDatos = false;
        TarjetasFactory.getTarjetas($scope.datosPersona.persona)
                .success(function (dat) {
                    $.unblockUI();
                    
                    if (dat.estado === 0) {
                        console.log(dat.mensaje);
                        var array = dat.obj;
                        array.pop();
                        $scope.tableParams = new NgTableParams({count: 5}, {dataset: array, counts: []});
                        $scope.hayDatos = true;
                    } else {
                        $scope.error = true;
                    }

                })
                .error(function (err) {
                    console.log(err);
                    $.unblockUI();
                    toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                });

    }]);