var app = angular.module('CajaMedica');

app.controller('CreditosCtrl', ['$scope', '$window', 'NgTableParams', 'CreditosFactory', function ($scope, $window, NgTableParams, CreditosFactory, blockUI) {

    $scope.hayDatos = false;

    $.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
    $scope.datosPersona = localStorage.getItem("datosPersona");
    $scope.datosPersona = JSON.parse($scope.datosPersona);
    console.log($scope.datosPersona);
    CreditosFactory.getCreditos($scope.datosPersona.persona)
        .success(function (dat) {
            $.unblockUI();
            console.log(dat);
            if (dat.estado === 0) {
                console.log(dat.mensaje);
                $scope.hayDatos = true;
                var array = dat.obj;
                if (dat.obj[dat.obj.length - 1] === null) {
                    array.pop();
                }
                var item = array[0];
                $scope.cantidadCuotas = item.cantidad_de_cuotas;
                console.log(item);
                console.log($scope.cantidadCuotas);
                $scope.tableParams = new NgTableParams({ count: 12 }, { dataset: array, counts: [] });
            } else {
                $scope.hayDatos = false;
                $scope.error = true;
            }

        })
        .error(function (err) {
            $scope.hayDatos = false;
            console.log(err);
            $.unblockUI();
            toastr.error("Ocurrio un error con el servidor. Intentelo de nuevo");
        });

    $scope.diferenciaDias = function (dia1, dia2) {
        try {
            var d1 = dia1.split('/');
            var date1 = new Date(parseInt(d1[2], 10), parseInt(d1[1], 10) - 1, parseInt(d1[0], 10));
            var d2 = dia2.split('/');
            var date2 = new Date(parseInt(d2[2], 10), parseInt(d2[1], 10) - 1, parseInt(d2[0], 10));
            var dias = Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
            return dias;
        } catch (e) {
            return -1;
        }
    };
    $scope.diferenciaDiasActual = function (dia1) {
        try {
            var today = new Date();
            var dia2 = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
            var d1 = dia1.split('/');
            var date1 = new Date(parseInt(d1[2], 10), parseInt(d1[1], 10) - 1, parseInt(d1[0], 10));
            var d2 = dia2.split('/');
            var date2 = new Date(parseInt(d2[2], 10), parseInt(d2[1], 10) - 1, parseInt(d2[0], 10));
            var dias = Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
            return dias;
        } catch (e) {
        }


    };

    $scope.ordenCuota = 0;
    $scope.orderByCuota = function () {
        $scope.ordenVtoOriginal = 0;
        $scope.ordenVto = 0;
        $scope.ordenFechaPago = 0;
        if ($scope.ordenCuota === 2) {
            $scope.ordenCuota = 1;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { cuota: "asc" } }, { dataset: $scope.listaAportes, counts: [] });

        } else {
            $scope.ordenCuota = 2;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { cuota: "desc" } }, { dataset: $scope.listaAportes, counts: [] });
        }
    };
    $scope.ordenVtoOriginal = 0;
    $scope.orderByVtoOriginal = function () {
        $scope.ordenCuota = 0;
        $scope.ordenVto = 0;
        $scope.ordenFechaPago = 0;
        if ($scope.ordenVtoOriginal === 2) {
            $scope.ordenVtoOriginal = 1;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { vencimiento_original: "asc" } }, { dataset: $scope.listaAportes, counts: [] });

        } else {
            $scope.ordenVtoOriginal = 2;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { vencimiento_original: "desc" } }, { dataset: $scope.listaAportes, counts: [] });
        }
    };
    $scope.ordenVto = 0;
    $scope.orderByVto = function () {
        $scope.ordenCuota = 0;
        $scope.ordenVtoOriginal = 0;
        $scope.ordenFechaPago = 0;
        if ($scope.ordenVto === 2) {
            $scope.ordenVto = 1;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { fecha_vencimiento: "asc" } }, { dataset: $scope.listaAportes, counts: [] });

        } else {
            $scope.ordenVto = 2;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { fecha_vencimiento: "desc" } }, { dataset: $scope.listaAportes, counts: [] });
        }
    };
    $scope.ordenFechaPago = 0;
    $scope.orderByFechaPago = function () {
        $scope.ordenCuota = 0;
        $scope.ordenVtoOriginal = 0;
        $scope.ordenVto = 0;
        if ($scope.ordenFechaPago === 2) {
            $scope.ordenFechaPago = 1;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { fecha_pago: "asc" } }, { dataset: $scope.listaAportes, counts: [] });

        } else {
            $scope.ordenFechaPago = 2;
            $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { fecha_pago: "desc" } }, { dataset: $scope.listaAportes, counts: [] });
        }
    };

    $scope.verDetalles = function (credito) {
        $.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
        //            $scope.tableParamsDetalles = new NgTableParams({count: 5}, {dataset: [], counts: []});
        CreditosFactory.getDetallesCredito($scope.datosPersona.persona, credito.credito)
            .success(function (dat) {
                $.unblockUI();
                console.log(dat);
                if (dat.estado === 0) {
                    console.log(dat.mensaje);
                    var cabezera = dat.obj[0];
                    var total = cabezera.total_capital + cabezera.total_intereses;
                    var array = dat.obj;
                    array.pop();
                    var cuota = 0;
                    var monto = 0;
                    var nroCuota = 0;
                    $scope.listaAportes = array;
                    array.forEach(function (item, i) {
                        var saldo = 0;
                        if (item.tipo_obligacion === 'CREDITO') {
                            cuota += item.capital + item.intereses + item.c_monto_seguro;
                            saldo = total + (cuota * -1);
                            monto = item.capital + item.intereses + item.c_monto_seguro;
                        }
                        array[array.length - i - 1].saldo = saldo;
                        item.monto = monto;
                        if (item.fecha_pago != null) {
                            //si ya se pago se ve la diferencia entre la fecha de vencimiento y la de pago
                            var diferencia = $scope.diferenciaDias(item.fecha_vencimiento, item.fecha_pago);
                            if (diferencia >= 0) {
                                item.diasAtraso = diferencia;
                            } else {
                                item.diasAtraso = 0;
                            }
                        } else {
                            //si no hay fecha de pago se hace la diferencia entre la fecha de vencimiento y la fecha actual
                            var dias2 = $scope.diferenciaDiasActual(item.fecha_vencimiento);
                            if(dias2 > 0){
                                item.diasAtraso = dias2;
                                console.log("dias2",dias2 );
                            } else {
                                item.diasAtraso = 0;
                            }
                        }
                        
                        //                                item.diasAtraso = diferencia;
                        //                                item.diasAtraso = 1;
                    });
                    $scope.ordenCuota = 1;
                    $scope.listaAportes = dat.obj;

                    $scope.listaAportes.forEach(function (item) {
                        try {
                            var from = item.vencimiento_original.split("/");
                            var f = new Date(from[2], from[1] - 1, from[0]);
                            item.vencimiento_original = f;
                        } catch (e) {
                        }
                        try {
                            var from = item.fecha_vencimiento.split("/");
                            var f = new Date(from[2], from[1] - 1, from[0]);
                            item.fecha_vencimiento = f;
                        } catch (e) {
                        }
                        try {
                            var from = item.fecha_pago.split("/");
                            var f = new Date(from[2], from[1] - 1, from[0]);
                            item.fecha_pago = f;
                        } catch (e) {
                        }



                    });
                    $scope.tableParamsDetalles = new NgTableParams({ count: 1000, sorting: { cuota: "asc" } }, { dataset: dat.obj, counts: [] });
                    $('#detalles').modal('show');
                } else {
                    $scope.error = true;
                }

            })
            .error(function (err) {
                console.log(err);
                $.unblockUI();
                toastr.error("Ocurrio un error con el servidor. Intentelo de nuevo");
            });
    };

    $scope.generarPDf = function () {

        $scope.datosPersona = localStorage.getItem("datosPersona");
        $scope.datosPersona = JSON.parse($scope.datosPersona);
        //        var lista []
        $.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
        CreditosFactory.GeneraPdf($scope.datosPersona, $scope.listaAportes)
            .success(function (data) {
                console.log(data);
                $.unblockUI();
                window.location.replace("/SwitchCajaMedica-web/ReporteCredito");
                $scope.blockui = false;
            })
            .error(function (error) {
                console.log(error);
            });

    };


}]);
