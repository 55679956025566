/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('ExtCreditoFactory', ["$http", function ($http) {
    return {
        getCreditos: function (payload) {
            /*
            var payload = {
                "persona": 10019,
                "periodoInicio": '2020-01-01',
                "periodoFin":  '2020-12-31'
            };
            */
            return $http.post("/SwitchCajaMedica-web/resource/services/extractoObligacionesCreditos", payload);
        },
        generarPdf2: function(extracto) {
            return $http.post("/SwitchCajaMedica-web/ReporteExtractoCredito", extracto);
        },
        generarDatosPdf: function(payload) {
            return $http.post("/SwitchCajaMedica-web/resource/services/datosExtracto", payload);
        },
        GeneraPdf: function (persona, lista, datosUtiles, total) {
//            json = {
//                valorRepor: lista,
//                tipo: 1,
//                inicio: "inicio",
//                fin: "fin"
//            };
            persona.valorRepor = lista;
            persona.tipo = 1;
//            persona.inicio = "";
//            persona.fin = "fin";
            persona.codigo_barra = lista[0].codigo_barra.substr(1, lista[0].codigo_barra.length-2);
            persona.atencion_zona = persona.zona;
            persona.vencimiento = lista[0].fecha_vencimiento;
            persona.asesor = lista[0].asesor;
            persona.estado_cuenta = lista[0].fecha_corte;
            persona.saldo_Credito = datosUtiles.saldo_Credito;
            persona.total_us = datosUtiles.total_us;
            persona.total_gs = datosUtiles.total_gs;
            persona.total_us_comision = datosUtiles.total_us_comision;
            persona.total_gs_comision = datosUtiles.total_gs_comision;
            persona.comision_extracto_gs = datosUtiles.comision_extracto_gs;
            persona.comision_extracto_us = datosUtiles.comision_extracto_us;
            persona.comision_mas_iva = datosUtiles.comision_extracto_gs;
            persona.comision_mas_iva_us = datosUtiles.comision_extracto_us;
            persona.total_capital_gs = datosUtiles.total_capital_gs;
            persona.total_interes_gs = datosUtiles.total_interes_gs;
            persona.total_mora_gs = datosUtiles.total_mora_gs;
            persona.total_capital_us = datosUtiles.total_capital_us;
            persona.total_interes_us = datosUtiles.total_interes_us;
            persona.total_mora_us = datosUtiles.total_mora_us;
            persona.fechaDesde = lista[0].fecha_vencimiento;
            var len = lista.length;
            persona.fechaHasta = lista[len - 1 ].fecha_vencimiento;

            persona.aviso = lista[0].aviso;
            persona.fecha_vencimiento1 = lista[0].fecha_vencimiento;
            persona.venc_segun_modalidad1 = lista[0].fecha_vencimiento;
//            persona.totalAporteJub1 = datosUtiles.saldo;
//            persona.totalFam1 = lista[1].saldo;
//            persona.totalFap1 = lista[2].saldo;
            var p = $http.post("/SwitchCajaMedica-web/ReporteExtractoCredito", persona);
            return p;
        },
        getComision: function (cobrador, total, moneda, tipoObligacion) {
            var json = {
                cobrador: cobrador,
                total: total,
                moneda: moneda,
                tipoObligacion: tipoObligacion
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getComisionExtracto", json);
        },
        getSaldoCredito: function (credito, fechaCorte) {
            var json = {
                credito: credito,
                fechaCorte: fechaCorte
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getSaldoCredito", json);
        }
    };
}]);

app.controller('ExtCreditoCtrl', ['$scope', '$window', 'NgTableParams', 'ExtCreditoFactory', '$timeout', function ($scope, $window, NgTableParams, ExtCreditoFactory, blockUI, $timeout) {
//        var self = this;

    var meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

        var dateFomr = function () {
            function pad(s) {
                return (s < 10) ? '0' + s : s;
            }
            var d = new Date();
            return [pad(d.getMonth() + 1), d.getFullYear()].join('/');
        };
        var dateTest = dateFomr();
        //console.log(dateTest);
        $scope.periodo = dateTest;

//        $('#datetimepicker4').datetimepicker({
//            
//        });

        $('#datetimepicker4').datetimepicker({
            viewMode: 'years',
            format: 'MM/YYYY',
            locale: 'es'
        });

        $scope.datosPersona = localStorage.getItem("datosPersona");
        $scope.datosPersona = JSON.parse($scope.datosPersona);
        
        $scope.hayDatos = false;
        $scope.datosUtiles = {};

        $scope.cargar = function () {
            var payload = {
                persona: angular.copy($scope.datosPersona.persona)
            };
            var pickerVal = $('#datetimepicker4').val();
            //console.log(pickerVal);
            if (pickerVal !== '') {
                $scope.periodo = $('#datetimepicker4').val();
            }
            payload.periodoFin = $scope.periodo.split("/");
            payload.periodoFin = payload.periodoFin[1] + payload.periodoFin[0] + (new Date()).getDate();

            //payload.persona = 10019;
//            payload.persona = 86;
            
            //console.log("Payload");
            //console.log(payload);
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            ExtCreditoFactory.getCreditos(payload)
                    .success(function (dat) {
                        $.unblockUI();
                        if (dat.estado === 0) {
                            
                            $scope.listaExtractoCreditos = dat.obj;
                            $scope.hayDatos = true;
                            
                            if(dat.obj.length == 0) {
                                $scope.hayDatos = false;
                            }
                            var data = dat.obj;
                            $scope.tableParams = new NgTableParams({count: 10}, {dataset: data, counts: [5, 10]});
                        } else {
                            $scope.hayDatos = false;
                        }
                    })
                    .error(function (err) {
                        $scope.hayDatos = false;
                        $.unblockUI();
                        console.log(err);
                        toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                    });
        };

        $scope.cargar();

        $scope.generarPDf2 = function(extracto) {

            var pv = extracto.primer_vencimiento.split("/");
            pv = pv[2] + pv[1] + pv[0];

            var vuc = extracto.vencimiento_ult_cuota.split("/");
            vuc = vuc[2] + vuc[1] + vuc[0];

            var payload = {
                credito: extracto.credito,
                primer_vencimiento: pv,
                vencimiento_ult_cuota: vuc,
                moneda: extracto.moneda
            }
            /*
            payload = {
                credito: 14257,
                primer_vencimiento: "20200808",
                vencimiento_ult_cuota: "20200808",
                moneda: "DL"
            }
            */
            //console.log("Payload");
            //console.log(payload);
            
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            ExtCreditoFactory.generarDatosPdf(payload)
                    .success(function (data) {
                        $.unblockUI();
                        
                        $scope.detallesCrédito = data.obj;

                        $scope.total_gs = 0;
                            $scope.total_us = 0;
                            $scope.comisionGS = 0;
                            $scope.v_cobrador = 0;
                            $scope.total_capital_gs = 0;
                            $scope.total_interes_gs = 0;
                            $scope.total_mora_gs = 0;
                            $scope.total_capital_us = 0;
                            $scope.total_interes_us = 0;
                            $scope.total_mora_us = 0;
                            if ($scope.detallesCrédito !== null && $scope.detallesCrédito.length > 0) {
                                $scope.hayDatos = true;

                                $scope.v_cobrador = $scope.detallesCrédito[0].v_cobrador;
                                $scope.credito = $scope.detallesCrédito[0].credito;
                                $scope.fecha_corte = $scope.detallesCrédito[0].fecha_corte;
                                ExtCreditoFactory.getSaldoCredito($scope.credito, $scope.fecha_corte)
                                        .success(function (data) {
                                            $scope.saldoCredito = data.obj;
                                        })
                                        .error(function (error) {
                                            console.log(error);
                                        });
                                $scope.detallesCrédito.forEach(function (item, index) {
                                    if (item.moneda === 'GS') {
                                        $scope.total_gs += item.capital + item.interes + item.interes_mora + item.gasto_cobranza;
                                        $scope.total_capital_gs += item.capital;
                                        $scope.total_interes_gs += item.interes;
                                        $scope.total_mora_gs += item.interes_mora;
                                    } else {
                                        $scope.total_us += item.capital + item.interes + item.interes_mora + item.gasto_cobranza;
                                        $scope.total_capital_us += item.capital;
                                        $scope.total_interes_us += item.interes;
                                        $scope.total_mora_us += item.interes_mora;
                                    }
                                    /*
                                    var mes = meses.indexOf(data.obj[index].fecha_vencimiento.toLowerCase().substr(0, 3)) + 1;
                                    
                                    var dia = data.obj[index].fecha_vencimiento.substr(4,1);
                                    var anho = data.obj[index].fecha_vencimiento.substr(8, data.obj[index].fecha_vencimiento.length - 1);
                                    data.obj[index].fecha_vencimiento =  dia + "/" + mes + "/" + anho;
                                    */
                                });
                                $scope.comision_extracto_us = $scope.total_us * $scope.detallesCrédito[0].porcentaje_comision / 100;
                                ExtCreditoFactory.getComision($scope.v_cobrador, $scope.total_gs, 'GS', 'C')
                                        .success(function (data) {
                                            if (data.estado === 0) {
                                                $scope.comision_extracto_gs = data.obj;
                                                $scope.generarPDf();
                                            }
                                        })
                                        .error(function (error) {
                                            console.log(error);
                                        });
                            }
                    })
                    .error(function (data6, status) {
                        $.unblockUI();
                        console.log(status);
                        console.log(data6);
                        $scope.blockui = false;
                    });
            /*
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            ExtCreditoFactory.generarPdf2(extracto)
                    .success(function (data6) {
                        $.unblockUI();
                        window.location.replace("/SwitchCajaMedica-web/ReporteExtractoCredito");
                        $scope.blockui = false;
                    })
                    .error(function (data6, status) {
                        $.unblockUI();
                        console.log(status);
                        console.log(data6);
                        $scope.blockui = false;
                    });
                    */
        };

        $scope.generarPDf = function () {
            $scope.datosUtiles.comision_extracto_gs = Number($scope.comision_extracto_gs.toFixed(2));
            $scope.datosUtiles.comision_extracto_us = Number($scope.comision_extracto_us.toFixed(2));
            $scope.datosUtiles.total_us = $scope.total_us;
            $scope.datosUtiles.total_gs = $scope.total_gs;
            $scope.datosUtiles.total_gs_comision = $scope.total_gs + $scope.comision_extracto_gs;
            $scope.datosUtiles.total_us_comision = $scope.total_us + $scope.comision_extracto_us;
            $scope.datosUtiles.total_gs_comision = Number($scope.datosUtiles.total_gs_comision.toFixed(2));
            $scope.datosUtiles.total_us_comision = Number($scope.datosUtiles.total_us_comision.toFixed(2));
//            $scope.datosUtiles.$total_us = $scope.total_us;
            $scope.datosUtiles.total_capital_gs = $scope.total_capital_gs;
            $scope.datosUtiles.total_interes_gs = $scope.total_interes_gs;
            $scope.datosUtiles.total_mora_gs = $scope.total_mora_gs;
            $scope.datosUtiles.total_capital_us = $scope.total_capital_us;
            $scope.datosUtiles.total_interes_us = $scope.total_interes_us;
            $scope.datosUtiles.total_mora_us = $scope.total_mora_us;
            $scope.datosUtiles.saldo_Credito = $scope.saldoCredito;
            
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            ExtCreditoFactory.GeneraPdf($scope.datosPersona, $scope.detallesCrédito, $scope.datosUtiles)
                    .success(function (data6) {
                        $.unblockUI();
                        window.location.replace("/SwitchCajaMedica-web/ReporteExtractoCredito");
                        $scope.blockui = false;
                    })
                    .error(function (data6, status) {
                        $.unblockUI();
                        console.log(status);
                        console.log(data6);
                        $scope.blockui = false;
                    });
                    
        };


    }]);