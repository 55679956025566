/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
var app = angular.module('CajaMedica');

app.factory('BancardService', ["$http", function ($http) {
    return {
        singleBuy: function (persona,moneda,creditos,monto, tipoPago) {
            var obj = {
                "persona": persona,
                "moneda": moneda,
                "creditos": creditos,
                "monto":1,
                "tipoPago":tipoPago
            };
            return $http.post("/SwitchCajaMedica-web/resource/pagoService/singleBuy", obj);
        },
        confirmation: function () {
            var obj = {
                "processId": localStorage.getItem('processId')
            };
            return $http.post("/SwitchCajaMedica-web/resource/pagoService/confirmation", obj);
        },
        rollback: function () {
            var obj = {
                "processId": localStorage.getItem('processId')
            };
            return $http.post("/SwitchCajaMedica-web/resource/pagoService/rollback", obj);
        }
        
    };
}]);

