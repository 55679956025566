app.filter("hora", function() {
    return function(input) {
        return input.substring(0,9);
    }
});

app.factory('UtilFactory', function () {
    return {
        blockUI: function () {
            $.blockUI.defaults.css = {};
            $.blockUI({
                message: '<div>' + '<h1><i class="fa fa-circle-o-notch fa-spin fa-2x"></i></h1>',
                overlayCSS: {
                    cursor: 'auto'
                },
                css: {
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    'margin-right': '-50%',
                    transform: 'translate( -50%,-50%)',
                    '-ms-transform': 'translate( -50%,-50%)',
                    '-webkit-transform': 'translate(-50%,- 50%)',
                    '-webkit-border-radius': '10px',
                    '-moz-border-radius': '10px',
                    opacity: 0.3,
                    color: '#fff'
                }
            });
        },
        unblockUI: function () {
            $.unblockUI();
        },
        alertBloqueante: function (mensaje, callback) {
            bootbox.alert(mensaje, callback);
        },
        confirm: function (mensaje, callback) {
            bootbox.confirm(mensaje, callback);
        },
        alert: function (mensaje, callback) {
            toastr.info(mensaje, '');
        },
        downloadFile: function (response, name) {
            var headers = response.headers();
            var filename = headers['x-filename'];
            var contentType = headers['content-type'];

            if(name != undefined) {
                filename = name;
            }

            var linkElement = document.createElement('a');

            var blob = new Blob([response.data], {type: contentType});
            var url = window.URL.createObjectURL(blob);

            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", filename);
            if (navigator.msSaveBlob) {
                return navigator.msSaveBlob(blob, filename);
            } else {
                var clickEvent = new MouseEvent("click", {
                    "view": window,
                    "bubbles": true,
                    "cancelable": false
                });
                linkElement.dispatchEvent(clickEvent);
            }
        }
    };
});