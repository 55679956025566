/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('PagoAporteDLFactory', ["$http", function ($http) {
    return {
        getCreditos: function (persona) {
            var obj = {
                "persona": persona
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/historialCreditos", obj);
        }, //detalleCredito
        getAportes: function (persona) {
            var obj = {
//                "persona": persona,
                "persona": 841,
                "moneda": 'DL'
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/cajeroConsultaAportesUS", obj);
        },
        getDatos: function (ci) {
            var obj = {
                "ci": ci
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getPersonaci", obj);
        }
    };
}]);

app.controller('PagoAporteDLCtrl', ['$scope', '$window', 'NgTableParams', 'PagoAporteDLFactory', function ($scope, $window, NgTableParams, PagoAporteDLFactory) {



        $scope.datosPersona = localStorage.getItem("datosPersona");
        $scope.datosPersona = JSON.parse($scope.datosPersona);
        //console.log($scope.datosPersona);

        $scope.cargar = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            PagoAporteDLFactory.getAportes($scope.datosPersona.persona)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                        if (dat.estado === 0) {
                            if (dat.obj !== null && dat.obj.length > 0) {
                                $scope.seConsulto = true;
                                $scope.hayDatos = true;
                                console.log(dat.mensaje);
                                var array = dat.obj;
                                array.pop();
                                $scope.tableParams = new NgTableParams({count: 12}, {dataset: dat.obj, counts: []});
                            }

                        } else {
                            $scope.error = true;
                        }

                    })
                    .error(function (err) {
                        console.log(err);
                        $.unblockUI();
                    });

        };

        try {
            console.log("consultando... " + $scope.datosPersona.persona);
//            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            $scope.cargar();
        } catch (e) {
            $.unblockUI();
            console.log(e);
        }

        $scope.consultarSocio = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            PagoAporteDLFactory.getDatos($scope.cedula)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                        if (dat.obj !== null && dat.obj.length > 0) {

                            $scope.hayDatos = true;
                            localStorage.setItem("haydatos", "true");
                            $scope.persona = dat.obj[0];
                            localStorage.setItem("persona", JSON.stringify($scope.persona));
                            console.log($scope.persona);
                            $scope.datosPersona = localStorage.getItem("persona");
                            $scope.datosPersona = JSON.parse($scope.datosPersona);
                            //console.log($scope.datosPersona);
                            $scope.cargar();
                        }
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                    });
        };

        $scope.sumaTotal = 0;
        $scope.checkbox = function (credito) {
            if (credito.check) {
                $scope.sumaTotal = $scope.sumaTotal + credito.saldo;
            } else {
                $scope.sumaTotal = $scope.sumaTotal - credito.saldo;
            }
        };

        $scope.pagar = function () {
            if ($scope.sumaTotal === 0) {
                toastr.warning("El monto a pagar es 0");
            } else {
                toastr.info("pagando " + $scope.sumaTotal);
            }

        };



    }]);