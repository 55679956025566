/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('AportesPendientesDLFactory', ["$http", function($http) {
    return {
        getCreditos: function(persona) {
            var obj = {
                "persona": persona
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/historialCreditos", obj);
        }, //detalleCredito
        getAportes: function(persona) {
            var obj = {
                "persona": persona,
                "moneda": 'DL'
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/cajeroConsultaAportesUS", obj);
            //            return $http.post("/SwitchCajaMedica-web/resource/services/cajeroConsultaAportes", obj);
        },
        getDatos: function(ci) {
            var obj = {
                "ci": ci
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getPersonaci", obj);
        }
    };
}]);

app.controller('AportesPendientesDlCtrl', ['$scope', '$window', 'NgTableParams', 'AportesPendientesDLFactory', 'CajeroInicioFactory', function($scope, $window, NgTableParams, AportesPendientesDlFactory, CajeroInicioFactory) {



    $scope.datosPersona = localStorage.getItem("persona");
    $scope.datosPersona = JSON.parse($scope.datosPersona);
    //console.log($scope.datosPersona);

    $scope.cargar = function() {
        $scope.hayDatos = false;
        $.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
        AportesPendientesDlFactory.getAportes($scope.datosPersona.persona)
            .success(function(dat) {
                $.unblockUI();
                console.log(dat);
                if (dat.estado === 0) {
                    $scope.seConsulto = true;

                    console.log(dat.mensaje);
                    if (dat.obj !== null && dat.obj.length > 0) {
                        $scope.hayDatos = true;
                    }
                    $scope.tableParams = new NgTableParams({ count: 12 }, { dataset: dat.obj, counts: [] });
                } else {
                    $scope.error = true;
                }

            })
            .error(function(err) {
                console.log(err);
                $.unblockUI();
            });

    };

    try {
        console.log("consultando... " + $scope.datosPersona.persona);
        $.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
        $scope.cargar();
    } catch (e) {
        $.unblockUI();
        console.log(e);
    }

    $scope.consultarSocio = function() {
        $.blockUI({ css: { backgroundColor: 'transparent', color: '#fff', border: 'none' }, message: '<img src="static/imagenes/ellipsis.gif" />' });
        AportesPendientesDlFactory.getDatos($scope.cedula)
            .success(function(dat) {
                $.unblockUI();
                console.log(dat);
                if (dat.obj !== null && dat.obj.length > 0) {

                    $scope.hayDatos = true;
                    localStorage.setItem("haydatos", "true");
                    $scope.persona = dat.obj[0];
                    localStorage.setItem("persona", JSON.stringify($scope.persona));
                    console.log($scope.persona);
                    $scope.datosPersona = localStorage.getItem("persona");
                    $scope.datosPersona = JSON.parse($scope.datosPersona);
                    //console.log($scope.datosPersona);
                    $scope.cargar();
                }
            })
            .error(function(err) {
                $.unblockUI();
                console.log(err);
            });
    };

    $scope.sumaTotal = 0;
    $scope.checkbox = function(credito) {
        if (credito.check) {
            $scope.sumaTotal = $scope.sumaTotal + credito.saldo;
        } else {
            $scope.sumaTotal = $scope.sumaTotal - credito.saldo;
        }
    };




}]);