
/* global angular */

var app = angular.module('CajaMedica');
app.controller('RecuperarCtrl', ['$scope', '$window', 'NgTableParams', 'RecuperarFactory', '$state', '$timeout', function ($scope, $window, NgTableParams, RecuperarFactory, $state, $timeout) {
        console.log("login controller");
        localStorage.setItem("datosPersona",null);
        localStorage.setItem('confirmarPago',false);


        $scope.error = false;
        $scope.login = {};
        
        $scope.email = "";
        $scope.seEnvio = false;
        $scope.mailNoValido = false;
        $scope.cedula = "";
        $scope.documento = "";
        $scope.newpass;
        $scope.newpass2;
        $scope.enviar = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            RecuperarFactory.enviarMail($scope.email,$scope.documento)
                    .success(function (data) {
                        $.unblockUI();
                        console.log(data);
                        if (data.estado === 0) {
                            $scope.seEnvio = true;
                            $scope.mailNoValido = false;
                            $scope.mensajeRecuperar = 'Se envio un codigo de recuperacion a su correo';
                        } else {
                            $scope.mailNoValido = true;
                            $scope.mensajeRecuperar = 'Correo no valido';
                        }
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                    });
        };
        $scope.recuperar = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            if ($scope.newpass !== $scope.newpass2) {
                $.unblockUI();
                alert("No coinciden las contraseñas");
            } else {
                RecuperarFactory.recuperarPassword($scope.email, $scope.newpass, $scope.codigoseguridad, $scope.documento)
                        .success(function (data) {
                            console.log(data);
                            $.unblockUI();
                            if (data.estado === 0) {
                                $scope.seEnvio = true;
                                $scope.mensajeRecuperar = 'Se reseteo con exito la contraseña';
                            }
                        })
                        .error(function (err) {
                            console.log(err);
                        });
            }

        };
    }]);
    