/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('IndexFactory', ["$http", function ($http) {
    return {
        logout: function () {
            return $http.get("/SwitchCajaMedica-web/resource/services/logout");
        },
        resetearPass: function (documento, newpass, captcha) {
            var obj = {
                "documento": documento,
                "newPassword": newpass,
                "captcha": captcha
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/resetPassword", obj);
        },
        pin: function () {
            return $http.get("/SwitchCajaMedica-web/resource/test/ping");
        },
        getUrl: function () {
            return $http.get("/SwitchCajaMedica-web/resource/test/getRediccionar");
        },
        getTelefonos: function (persona) {
            var obj = {
                "persona": persona
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getTelefonos", obj);
        },
        actualizarDatos: function (data) {
            console.log(data);
            var obj = {
                "persona": data.persona,
                "telefono": data.telefono,
                "celular": data.celular,
                "email": data.correo,
                "direccion": data.direccion,
                "lugarLaboral": data.lugarTrabajo,
                "direccionLaboral": data.direccionTrabajo,
                "telefonoLaboral": data.telefonoTrabajo
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/actualizarDatos", obj);
        },
        getTrabajos: function (persona) {
            var obj = {};
            obj.persona = persona;
            return $http.post("/SwitchCajaMedica-web/resource/services/getLugaresTrabajo", obj);
        }
    };
}]);



app.controller('IndexCtrl', ['$scope', '$window', 'NgTableParams', '$timeout', 'IndexFactory', function ($scope, $window, NgTableParams, $timeout, IndexFactory) {
        console.log('IndexCtrl');

//        localStorage.setItem('confirmarPago',false);
//        console.log(localStorage.getItem('confirmarPago'));
        

        IndexFactory.getUrl()
                .success(function (data) {
                    console.log(data);
                    localStorage.setItem('urlr', data.mensaje);
                })
                .error(function (error) {
                    console.log(error);
                });
        
        IndexFactory.pin();
        
        $scope.datosPersona = localStorage.getItem("datosPersona");
        //console.log($scope.datosPersona);
        console.log(JSON.parse(localStorage.getItem("datosPersona")));
        $scope.datosPersona = JSON.parse($scope.datosPersona);

        if (location.pathname > 0 && $scope.datosPersona.sexo === 'F') {
            $scope.saludo = 'Bienvenida';
        } else {
            $scope.saludo = 'Bienvenido';
        }
        $scope.tipo = localStorage.getItem("tipo");
        console.log($scope.tipo);






        $scope.newpass;
        $scope.newpass2;
        $scope.resetearPass = function () {
            if ($scope.newpass !== $scope.newpass2) {
                alert("No coinciden las contraseñas");
            } else {
                $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<h3>Cargando...</h3><img src="static/imagenes/ellipsis.gif" />'});
                IndexFactory.resetearPass($scope.datosPersona.cedula_identidad, $scope.newpass, $scope.captcha)
                        .success(function (dat) {
                            $.unblockUI();
                            console.log(dat);
                            if (dat.estado === 0) {
                                toastr.info("Contraseña cambiada con exito");
                            } else {
                                toastr.error("Ocurrio un error con el servidor, vuelva a intentarlo");
                            }
                        })
                        .error(function (err) {
                            $.unblockUI();
                            console.log(err);
                            toastr.error("Ocurrio un error con el servidor, vuelva a intentarlo");
                        });
            }
        };
        try {
            $scope.newDato = {};
            $scope.newDato.persona = $scope.datosPersona.persona;
            $scope.newDato.nombre = $scope.datosPersona.primer_nombre + ' ' + $scope.datosPersona.segundo_nombre + ' ' + $scope.datosPersona.primer_apellido + ' ' + $scope.datosPersona.segundo_apellido;
            $scope.newDato.nombre = $scope.newDato.nombre.replace('null', '');
            $scope.newDato.telefono;
            $scope.newDato.correo = $scope.datosPersona.email;
            $scope.newDato.direccion = $scope.datosPersona.direccion_residencia;
            $scope.newDato.celular;
            $scope.newDato.lugarTrabajo;
            $scope.newDato.direccionTrabajo;
            $scope.newDato.telefonoTrabajo;
        } catch (e) {
            console.log(e);
        }



        $scope.levantarModalUpdate = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            IndexFactory.getTelefonos($scope.datosPersona.persona)
                    .success(function (dat) {
                        $.unblockUI();
                        $('#updateDatos').modal('show');
                        console.log(dat);
                        if (dat.estado === 0 && dat.obj !== null) {
                            var array = dat.obj;
                            array.forEach(function (item, i) {
                                if (item.tipo === 'TE') {
                                    $scope.newDato.telefono = item.numero;
                                } else if (item.tipo === 'CE') {
                                    $scope.newDato.celular = item.numero;
                                }
                            });
                        }
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                        toastr.error("Ocurrio un error al traer los numero de telefono.");
                    });

            IndexFactory.getTrabajos($scope.datosPersona.persona)
                    .success(function (dat) {
                        $.unblockUI();
                        $('#updateDatos').modal('show');
                        console.log(dat);
                        if (dat.estado === 0 && dat.obj !== null) {
                            var trabajo = dat.obj[0];
                            console.log(trabajo);
                            $scope.newDato.lugarTrabajo = trabajo.lugar_laboral;
                            $scope.newDato.direccionTrabajo = trabajo.direccion;
                            $scope.newDato.telefonoTrabajo = trabajo.telefonos;
                        }
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                        toastr.error("Ocurrio un error al traer los datos laborales");
                    });
        };

        $scope.actualizarDatos = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            IndexFactory.actualizarDatos($scope.newDato)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                        if (dat.estado === 0) {
                            toastr.info("Se actualizo con exito");
                        } else {
                            toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                        }

                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                        toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                    });
        };

        $scope.logout = function () {
            console.log("logout");
            IndexFactory.logout()
                    .success(function (dat) {
                        console.log(dat);
                    })
                    .error(function (err) {
                        console.log(err);
                    });
        };





    }]);