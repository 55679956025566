/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.controller('LoginCajeroCtrl', ['$scope', '$window', 'NgTableParams', 'LoginCajeroFactory', '$state', '$timeout', function ($scope, $window, NgTableParams, LoginCajeroFactory, $state, $timeout) {
        console.log("LoginCajeroCtrl");

        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
        LoginCajeroFactory.logout()
                .success(function (dat) {
                    $.unblockUI();
                    var d = new Date();
                    $(".captcha").attr("src", "/SwitchCajaMedica-web/Captcha?" + d.getTime());
                    $('.captchaText').val(''); //txtID is textbox ID
                    console.log(dat);
                })
                .error(function (err) {
                    $.unblockUI();
                    var d = new Date();
                    $(".captcha").attr("src", "/SwitchCajaMedica-web/Captcha?" + d.getTime());
                    $('.captchaText').val(''); //txtID is textbox ID
                    console.log(err);
                });

        $scope.onSubmit = function(token) {
            $scope.login.token = token;
            $scope.doLogin();
            //alert('thanks ' + token);
        }

        $window.onSubmit = $scope.onSubmit;

        $scope.error = false;
        $scope.login = {};
        $scope.doLogin = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            LoginCajeroFactory.doLogin($scope.login)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                        if (dat.estado === 0) {
                            localStorage.setItem("tipo", "cajero");
                            localStorage.setItem("haydatos", "false");
                            localStorage.setItem("persona", null);
                            localStorage.setItem("url", dat.urlRedireccion);
                            $window.location.href = "#/app/creditosPendientesGs";
                        } else {
                            $scope.error = true;
                            grecaptcha.reset();
                            $scope.mensajeLogin = "No se ha podido autenticar el usuario";
                        }


                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                        $scope.error = true;
                        grecaptcha.reset();
                        $scope.mensajeLogin = dat.mensaje;
                    });
        };

        angular.element(document).ready(function () {
            console.log("cajero");
            setTimeout(function(){
                grecaptcha.render('submit', {
                    'sitekey' : '6LeePKUZAAAAANB49zEbNp6-eLjXs2-g55jEsAAE', 
                    'callback' : onSubmit
                });
            }, 500);
        });
    }]);