angular.module('CajaMedica', ['ui.router', 'ngTable', 'chart.js', 'angularjs-dropdown-multiselect', 'ngFileUpload'])

    .config(['$stateProvider', '$urlRouterProvider', '$httpProvider', function ($stateProvider, $urlRouterProvider, $httpProvider) {

      if (!$httpProvider.defaults.headers.get) {
          $httpProvider.defaults.headers.get = {};
      }

      // Answer edited to include suggestions from comments
      // because previous version of code introduced browser-related errors

      //disable IE ajax request caching
      $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
      // extra
      $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
      $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

      $stateProvider
        .state('login', {
          url: '/login',
          templateUrl: 'app/login/login.html',
          controller: 'LoginCtrl'
        })
        .state('logincajero', {
          url: '/logincajero',
          templateUrl: 'app/logincajero/view.html',
          controller: 'LoginCajeroCtrl'
        })
        .state('recuperar', {
          url: '/recuperar',
          templateUrl: 'app/recuperar/view.html',
          controller: 'RecuperarCtrl'
        })
        .state('app', {
          url: '/app',
          cache: false,
          abstract: true,
          templateUrl: 'app/menu/view.html',
          controller: 'MenuCtrl',
        })
        .state('app.inicio', {
          url: '/inicio',
          templateUrl: 'app/inicio/view.html',
          controller: 'InicioCtrl',
          cache: false
        })
        .state('app.solicitudes', {
          url: '/solicitudes',
          templateUrl: 'app/solicitudes/solicitudes.html',
          controller: 'SolicitudesCtrl',
          cache: false
        })
        .state('app.aportes', {
          url: '/aportes',
          templateUrl: 'app/aporte/view.html',
          controller: 'Aporte2Ctrl',
          cache: false
        })
        .state('app.aportesus', {
          url: '/aportesus',
          templateUrl: 'app/aporteus/view.html',
          controller: 'AporteUs2Ctrl',
          cache: false
        })
        .state('app.creditos', {
          url: '/creditos',
          templateUrl: 'app/creditos/view.html',
          controller: 'CreditosCtrl',
          cache: false
        })
        .state('app.tarjetasCredito', {
          url: '/tarjetasCredito',
          templateUrl: 'app/tarjetasCredito/view.html',
          controller: 'TarjetasCreditosCtrl',
          cache: false
        })
        .state('app.tarjetas', {
          url: '/tarjetas',
          templateUrl: 'app/tarjetas/tarjetas.html',
          controller: 'TarjetasCtrl',
          cache: false
        })
        .state('app.extracto_creditos', {
          url: '/extracto_creditos',
          templateUrl: 'static/templates/extracto_creditos.html',
          controller: 'ExtCreditoCtrl',
          cache: false
        })
        .state('app.extracto_aportes', {
          url: '/extracto_aportes',
          templateUrl: 'static/templates/extracto_aportes.html',
          controller: 'ExtAportesCtrl',
          cache: false
        })
        .state('app.cajero_inicio', {
          url: '/cajero_inicio',
          templateUrl: 'static/templates/cajero_inicio.html',
          controller: 'CajeroInicioCtrl',
          cache: false
        })
        .state('app.creditosPendientesGs', {
          url: '/creditosPendientesGs',
          templateUrl: 'static/templates/creditosPendientesGs.html',
          controller: 'CreditosPendientesGsCtrl',
          cache: false
        })
        .state('app.creditosPendientesDl', {
          url: '/creditosPendientesDl',
          templateUrl: 'static/templates/creditosPendientesDl.html',
          controller: 'CreditosPendientesDlCtrl',
          cache: false
        })
        .state('app.aportesPendientesGs', {
          url: '/aportesPendientesGs',
          templateUrl: 'static/templates/aportesPendientesGs.html',
          controller: 'AportesPendientesGsCtrl',
          cache: false
        })
        .state('app.aportesPendientesDl', {
          url: '/aportesPendientesDl',
          templateUrl: 'static/templates/aportesPendientesDl.html',
          controller: 'AportesPendientesDlCtrl',
          cache: false
        })
        .state('app.pagoservicio', {
          url: '/pagoservicio',
          templateUrl: 'static/templates/pagoservicios.html',
          controller: 'PagoServiciosCtrl',
          cache: false
        })
        .state('app.pagocreditosgs', {
          url: '/pagocreditosgs',
          templateUrl: 'static/templates/pagoscreditosGs.html',
          controller: 'PagoCreditosGSCtrl',
          cache: false
        })
        .state('app.pagocreditosdl', {
          url: '/pagocreditosdl',
          templateUrl: 'static/templates/pagoscreditosDl.html',
          controller: 'PagoCreditosDLCtrl',
          cache: false
        })
        .state('app.pagoaportegs', {
          url: '/pagoaportegs',
          templateUrl: 'static/templates/pagoAporteGs.html',
          controller: 'PagoAporteGSCtrl',
          cache: false
        })
        .state('app.pagoaportedl', {
          url: '/pagoaportedl',
          templateUrl: 'static/templates/pagoAporteDl.html',
          controller: 'PagoAporteDLCtrl',
          cache: false
        })
        .state('app.confirmacionpago', {
          url: '/confirmacionpago',
          templateUrl: 'static/templates/confirmacionPago.html',
          //                            controller: 'PagoAporteDLCtrl'
        })
        .state('app.comprobantes', {
          url: '/comprobantes',
          templateUrl: 'app/comprobantes/comprobantes.html',
          controller: 'ComprobantesCtrl',
          cache: false
        })
        ;

      $urlRouterProvider.otherwise('/login');
      var sessionInterceptor = ['$location', '$rootScope', '$injector', '$window', function ($location, $rootScope, $injector, $window) {
          return {
            response: function(resp) {
              if (resp.headers()['content-type'] === "application/json" ||
                    resp.headers()['content-type'] === 'application/json;charset=UTF-8') {
                //console.log($location.absUrl());
                //console.log($window.location.pathname.indexOf('login'));
                if (resp.data.status === 999 && $location.absUrl().indexOf('login') < 0 && $location.absUrl().indexOf('recuperar') < 0) {
                  console.log("Resp interceptor");
                  console.log(resp);
                  console.log($location.absUrl().indexOf('login'));
                  console.log($location.absUrl().indexOf('recuperar'));
                  window.location.href = '#login';
                  window.location.reload();
                  /*
                  console.log("redireccionando");
                  var url = localStorage.getItem('url');
                  console.log(url);
                  if (url === null) {
                    $window.location.href = "http://desa01.konecta.com.py/cajamedica";
                  } else {
                    $window.location.href = url;
                  }
                  */
                }
              }
              return resp;
            }
          };
        }];
      $httpProvider.interceptors.push(sessionInterceptor);
    }]);