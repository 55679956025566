
/* global angular */

var app = angular.module('CajaMedica');
app.controller('LoginCtrl', ['$scope', '$window', 'NgTableParams', 'LoginFactory', '$state', '$timeout', '$window', function ($scope, $window, NgTableParams, LoginFactory, $state, $timeout, $window) {
        console.log("login controller");
        localStorage.setItem("datosPersona", null);
        localStorage.setItem('confirmarPago', false);


        $scope.error = false;
        $scope.login = {};
        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
        LoginFactory.logout()
                .success(function (dat) {
                    $.unblockUI();
                    var d = new Date();
                    $(".captcha").attr("src", "/SwitchCajaMedica-web/Captcha?" + d.getTime());
                    $('.captchaText').val(''); //txtID is textbox ID
                    console.log("nose");
                    console.log(dat);
                })
                .error(function (err) {
                    $.unblockUI();
                    var d = new Date();
                    $(".captcha").attr("src", "/SwitchCajaMedica-web/Captcha?" + d.getTime());
                    $('.captchaText').val(''); //txtID is textbox ID
                    console.log(err);
                });

        $scope.onSubmit = function(token) {
            $scope.login.token = token;
            $scope.doLogin();
        }

        $window.onSubmit = $scope.onSubmit;

        $scope.doLogin = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            sessionStorage.captcha = $scope.login.captcha;
            $scope.error = false;
            LoginFactory.doLogin($scope.login)
                    .success(function (response) {
                        localStorage.setItem("url", response.urlRedireccion);
                        if (response.estado !== 0) {
                            $scope.error = true;
                            $scope.mensajeLogin = response.mensaje;
                            $scope.mensajeLogin = "No se ha podido autenticar el usuario";
                            grecaptcha.reset();
                            $.unblockUI();
                        } else {
                            try {
                                var persona = response.dato.persona;
                                LoginFactory.getDatos(persona)
                                        .success(function (dat2) {
                                            $.unblockUI();
                                            if (dat2.estado === 0) {
                                                console.log(dat2.obj[0]);
                                                console.log(JSON.stringify(dat2.obj[0]));
                                                localStorage.setItem("datosPersona", JSON.stringify(dat2.obj[0]));
                                                $.unblockUI();
                                                localStorage.setItem("tipo", "socio");
                                                $window.location.href = '.#/app/inicio';
                                            } else {
                                                $scope.error = true;
                                                $.unblockUI();
                                            }

                                        })
                                        .error(function (err2) {
                                            console.log("entro en err");
                                            console.log(err2);
                                        });
                            } catch (err) {
                                $scope.error = true;
                                $.unblockUI();
                                console.log(err);
                            }
                        }
                    })
                    .error(function (err) {
                        $scope.error = true;
                        grecaptcha.reset();
						console.log(err);
						$scope.mensajeLogin = "Ha ocurrido un error, intentelo nuevamente";
                        $.unblockUI();
//                        $.unblockUI();
                    });

//            location.replace("#/inicio");
        };
        $scope.email = "";
        $scope.seEnvio = false;
        $scope.mailNoValido = false;
        $scope.cedula = "";
        $scope.newpass;
        $scope.newpass2;
        $scope.enviar = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            LoginFactory.enviarMail($scope.email)
                    .success(function (data) {
                        $.unblockUI();
                        console.log(data);
                        if (data.estado === 0) {
                            $scope.seEnvio = true;
                            $scope.mailNoValido = false;
                            $scope.mensajeRecuperar = 'Se envio un codigo de recuperacion a su correo';
                        } else {
                            $scope.mailNoValido = true;
                            $scope.mensajeRecuperar = 'Correo no valido';
                        }
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                    });
        };
        $scope.recuperar = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            if ($scope.newpass !== $scope.newpass2) {
                $.unblockUI();
                alert("No coinciden las contraseñas");
            } else {
                LoginFactory.recuperarPassword($scope.email, $scope.newpass, $scope.codigoseguridad)
                        .success(function (data) {
                            console.log(data);
                            $.unblockUI();
                            if (data.estado === 0) {
                                $scope.seEnvio = true;
                                $scope.mensajeRecuperar = 'Se reseteo con exito la contraseña';
                            }
                        })
                        .error(function (err) {
                            console.log(err);
                        });
            }

        };

        angular.element(document).ready(function () {
            setTimeout(function(){
                grecaptcha.render('submit', {
                    'sitekey' : '6LeePKUZAAAAANB49zEbNp6-eLjXs2-g55jEsAAE', 
                    'callback' : onSubmit
                });
            }, 500);
        });
    }]);