/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');


app.factory('SolicitudesFactory', ["$http", function ($http) {
    return {
        getSolicitudes: function (persona) {
            var obj = {
                "persona": persona
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getSolicitudes", obj);
        },
        getDetalles: function (solicitud) {
            var obj = {
                "solicitud": solicitud
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getSolicitud", obj);
        }
    };
}]);

app.controller('SolicitudesCtrl', ['$scope', '$window', 'NgTableParams', '$timeout', 'SolicitudesFactory', function ($scope, $window, NgTableParams, $timeout, SolicitudesFactory) {
        console.log('SolicitudesCtrl');
        $scope.hayDatos = false;

        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});

        $scope.datosPersona = localStorage.getItem("datosPersona");
        $scope.datosPersona = JSON.parse($scope.datosPersona);
        //console.log($scope.datosPersona);

        SolicitudesFactory.getSolicitudes($scope.datosPersona.persona)
                .success(function (dat) {
                    $.unblockUI();
                    console.log(dat);
                    if (dat.obj !== null && dat.obj.length > 0) {
                        $scope.hayDatos = true;
                        var data = dat.obj;
                        $scope.tableParams = new NgTableParams({count: 10}, {dataset: data, counts: []});
                    }
                })
                .error(function (err) {
                    $.unblockUI();
                    console.log(err);
                    $scope.hayDatos = false;
                    toastr.error("Ocurrio un error con el servidor. Intentelo de nuevo");
                });



        $scope.verDetalles = function (solicitud) {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            console.log(solicitud);
            SolicitudesFactory.getDetalles(solicitud.numero)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                    });
        };

    }]);