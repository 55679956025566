var app = angular.module('CajaMedica');

app.factory('InicioFactory', ["$http", function ($http) {
  return {
    getProfesiones: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/profesiones");
    },
    getAsesores: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/asesores");
    },
    getPaises: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/paises");
    },
    getCiudades: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/ciudades");
    },
    getBarrios: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/barrios");
    },
    especialidades: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/especialidades");
    },
    getAportesGS: function (persona) {
      var obj = {
        "persona": persona,
        "moneda": "GS"
      };
      return $http.post("/SwitchCajaMedica-web/resource/services/inventarioAportes", obj);
    },
    getAportesUSD: function (persona) {
      var obj = {
        "persona": persona,
        "moneda": "DL"
      };
      return $http.post("/SwitchCajaMedica-web/resource/services/inventarioAportes", obj);
    },
    getTarjetas: function (persona) {
      var obj = {
        "persona": persona
      };
      return $http.post("/SwitchCajaMedica-web/resource/services/historialTarjeta", obj);
    },
    getCreditos: function (persona) {
      var obj = {
        "persona": persona
      };
      return $http.post("/SwitchCajaMedica-web/resource/services/historialCreditosResumen", obj);
    },
    getDatos: function (persona) {
      var obj = {};
      obj.persona = persona;
      return $http.post("/SwitchCajaMedica-web/resource/services/getPersona", obj);
    },
    getTelefonos: function (persona) {
      var obj = {
        "persona": persona
      };
      return $http.post("/SwitchCajaMedica-web/resource/services/getTelefonos", obj);
    },
    getTrabajos: function (persona) {
      var obj = {};
      obj.persona = persona;
      return $http.post("/SwitchCajaMedica-web/resource/services/getLugaresTrabajo", obj);
    },
    enviarSolicitud: function (datos) {
      return $http.post("/SwitchCajaMedica-web/resource/services/setSolicitudTarjetas", datos);
    },
    enviarSolicitudCredito: function (datos) {
      return $http.post("/SwitchCajaMedica-web/resource/services/setSolicitudCredito", datos);
    },
    getDestinos: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/destinos");
    },
    getDestinos: function () {
      return $http.get("/SwitchCajaMedica-web/resource/services/getDestinos");
    }
  };
}]);
