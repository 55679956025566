/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('CajeroInicioFactory', ["$http", function ($http) {
    return {
        getDatos: function (ci) {
            var obj = {
                "ci": ci
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/getPersonaci", obj);
        }
    };
}]);

app.controller('CajeroInicioCtrl', ['$scope', '$window', 'NgTableParams',  '$timeout', 'CajeroInicioFactory', function ($scope, $window, NgTableParams,  $timeout, CajeroInicioFactory) {

        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
        console.log("CajeroInicioCtrl");
        $.unblockUI();

        $scope.ciSocio;
        console.log(localStorage.getItem("haydatos"));
        if (localStorage.getItem("haydatos") === 'false') {
            console.log("entro, no deberia entrar si hay datos es: true"+localStorage.getItem("haydatos"));
            $scope.hayDatos = false;
        } else {
            $scope.hayDatos = true;
            $scope.persona = JSON.parse(localStorage.getItem("persona"));
        }

        

        $scope.consultarSocio = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            CajeroInicioFactory.getDatos($scope.ciSocio)
                    .success(function (dat) {
                        $.unblockUI();
                        console.log(dat);
                        if (dat.obj !== null && dat.obj.length > 0) {
                            $scope.hayDatos = true;
                            localStorage.setItem("haydatos","true");
                            $scope.persona = dat.obj[0];
                            localStorage.setItem("persona",JSON.stringify($scope.persona));
                            console.log($scope.persona);
                        }
                    })
                    .error(function (err) {
                        $.unblockUI();
                        console.log(err);
                    });
        };



    }]);