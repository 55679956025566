/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
var app = angular.module('CajaMedica');

app.controller('PagoServiciosCtrl', ['$scope', '$window', 'NgTableParams', '$state','BancardService',function ($scope, $window, NgTableParams,$state,BancardService) {
        console.log('PagoServiciosCtrl');
        
        var confirmarPago = localStorage.getItem('confirmarPago');
        console.log(confirmarPago);
        console.log(confirmarPago);
        $("#confirmacion").modal('hide');
        if(confirmarPago === 'true'){
            $("#confirmacion").modal('show');
            $scope.mensajeConfirmacion = localStorage.getItem("mensajeConfirmacion");
        }
        
        
        
        $scope.creditoGS = function(){
//            console.log("aprete el boton");
            $state.go('pagocreditosgs');
        };
        $scope.creditoDl = function(){
          console.log("apreta pagar aporte dolares");  
          $state.go('pagocreditosdl');
        };
        $scope.aporteGs = function(){
          console.log("pagoaporteGS");
          $state.go("pagoaportegs");
        };
        $scope.aporteDl = function(){
          console.log("pagoaportedl");
          $state.go("pagoaportedl");
        };
        
        $scope.confirmarPago = function(){
            localStorage.setItem('confirmarPago',false);
            toastr.info("confirmando Pago");
            $("#confirmacion").modal('hide');
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            BancardService.confirmation()
                    .success(function(data){
                        $.unblockUI();
                        console.log(data);
                    }).error(function(error){
                        $.unblockUI();
                        console.log(error);
                    });
        };
        
        $scope.cancelarPago = function(){
            console.log("cancelar pago");
            localStorage.setItem('confirmarPago',false);
            $("#confirmacion").modal('hide');
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            BancardService.rollback()
                    .success(function(data){
                        $.unblockUI();
                        console.log(data);
                    }).error(function(error){
                        $.unblockUI();
                        console.log(error);
                    });
            
        };

        
}]);