/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var app = angular.module('CajaMedica');

app.factory('ExpAportesFactory', ["$http", function ($http) {
    return {
        getAportes: function (persona, moneda) {
            var obj = {
                "persona": persona,
//              "persona": 10
                "moneda": moneda
            };
            return $http.post("/SwitchCajaMedica-web/resource/services/extractoObligacionesAporte", obj);
        },
        GeneraPdf: function (lista, persona, datosUtiles, total, moneda) {
            json = {
                valorRepor: lista,
                tipo: 1,
                inicio: "inicio",
                fin: "fin"
            };
            persona.valorRepor = lista;
            persona.tipo = 1;
//            persona.inicio = "";
//            persona.fin = "fin";
            persona.codigo_barra = lista[0].codigo_barra.substr(1, lista[0].codigo_barra.length-2);
            //persona.codigo_barra = lista[0].codigo_barra;
            persona.dist_utilidad = lista[0].dist_utilidad;
            persona.apellidos_y_nombres = lista[0].apellidos_y_nombres;
            persona.casilla_correo = lista[0].email;
            persona.atencion_zona = persona.zona;
            persona.vencimiento = datosUtiles.fecha_vencimiento;
            persona.asesor = datosUtiles.asesor;
            persona.estado_cuenta = datosUtiles.c_fecha_impresion;
            persona.fondo_aporte = datosUtiles.APORTE_acumulado.split('.').join("");
            persona.fondo_excedente = datosUtiles.utilidad_acumulada;
            persona.comision_mas_iva = datosUtiles.comision_mas_iva;
            persona.total = total;
            persona.totalConComision = total + datosUtiles.comision_mas_iva;
            persona.aviso = datosUtiles.aviso;
            persona.fecha_vencimiento1 = datosUtiles.fecha_vencimiento;
            persona.venc_segun_modalidad1 = datosUtiles.venc_segun_modalidad;
            persona.totalAporteJub1 = datosUtiles.saldo;
            persona.celular = lista[0].celular;

            persona.totalFam1 = 0;

            angular.forEach(lista, function(l) {
                if(l.clase_de_aporte == "FAM ") {
                    persona.totalFam1 += l.saldo;
                }
            });
            
            persona.totalFap1 =  0;

            angular.forEach(lista, function(l) {
                if(l.clase_de_aporte == "FAP ") {
                    persona.totalFap1 += l.saldo;
                }
            });

            persona.moneda = moneda;
            var p = $http.post("/SwitchCajaMedica-web/ReporteExtractoAporte", persona);
            return p;
        }
    };
}]);

app.controller('ExtAportesCtrl', ['$scope', '$window', 'NgTableParams', 'ExpAportesFactory', function ($scope, $window, NgTableParams, ExpAportesFactory) {
//        var self = this;
        $scope.hayDatos = false;

        $scope.datosPersona = localStorage.getItem("datosPersona");
        $scope.datosPersona = JSON.parse($scope.datosPersona);
        //console.log($scope.datosPersona);

        $scope.moneda = "GS";

        $scope.getAportes = function() {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            $scope.hayDatos = false;
            ExpAportesFactory.getAportes($scope.datosPersona.persona, $scope.moneda)
                    .success(function (dat) {
                        $.unblockUI();
                        if (dat.estado === 0) {
                            if (dat.obj !== null && dat.obj.length > 0) {
    
                                for(var i = 0; i <= dat.obj.length - 1; i++) {
                                    dat.obj[i].saldo = Number(dat.obj[i].saldo.split('.').join(""));
                                }

                                var saldoTotal = 0;
                                dat.obj.forEach(function (item, index) {
                                    saldoTotal += item.saldo;
                                });
    
                                $scope.datosutiles = dat.obj[0];
                                if(dat.obj.length != 0) {
                                    $scope.hayDatos = true;
                                }

                                var data = dat.obj;
                                $scope.listaExtractoAportes = dat.obj;
                                $scope.saldoTotal = saldoTotal;
                                $scope.tableParams = new NgTableParams({count: 10}, {dataset: data, counts: []});
                            }
                        } else {
                            $scope.hayDatos = false;
                            toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                        }
    
                    })
                    .error(function (err) {
                        $scope.hayDatos = false;
                        $.unblockUI();
                        console.log(err);
                        toastr.error('Ocurrio un error con el servidor. Intentelo de nuevo');
                    });
        }
        
        $scope.getAportes();

        $scope.generarPDf = function () {
            $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
            ExpAportesFactory.GeneraPdf($scope.listaExtractoAportes, $scope.datosPersona, $scope.datosutiles, $scope.saldoTotal, $scope.moneda)
                    .success(function (data6) {
                        $.unblockUI();
                        window.location.replace("/SwitchCajaMedica-web/ReporteExtractoAporte");
                        $scope.blockui = false;
                    })
                    .error(function (data6, status) {
                        $.unblockUI();
                        console.log(status);
                        console.log(data6);
                        $scope.blockui = false;
                    });
        };

    }]);