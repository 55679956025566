app.controller('ComprobantesCtrl', ["$scope", "ComprobantesService", "NgTableParams", "UtilFactory", function($scope, ComprobantesService, NgTableParams, UtilFactory) {


    var persona = JSON.parse(localStorage.getItem("datosPersona")).persona;

    //persona = 10019;

    console.log("Persona: " + persona);

    $scope.showFacturas = false;
    $scope.showRecibos = false;

    $scope.listarFacturas = function(persona) {
        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
        ComprobantesService.listarFacturas(persona).then(function(response) {
            $.unblockUI();
            try {
                if(response.data.estado == 0) {
                    $scope.showFacturas = true;
                    var facturas = response.data.dato;
                    $scope.tableFacturas = new NgTableParams({count: 5}, {dataset: facturas, counts: []});
                } else {
                    toastr.info(response.data.mensaje);
                }
            } catch(e) {
                console.log(e);
            }
        }).catch(function(e) {
            $.unblockUI();
            console.log(e);
        });
    }

    $scope.listarFacturas(persona);


    $scope.listarRecibos = function(persona) {
        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
        ComprobantesService.listarRecibos(persona).then(function(response) {
            console.log("Response");
            console.log(response);
            $.unblockUI();
            try {
                if(response.data.estado == 0) {
                    $scope.showRecibos = true;
                    var recibos = response.data.dato;
                    $scope.tableRecibos = new NgTableParams({count: 5}, {dataset: recibos, counts: []});
                } else {
                    toastr.info(response.data.mensaje);
                }
            } catch(e) {
                console.log(e);
            }
        }).catch(function(e) {
            $.unblockUI();
            console.log(e);
        });
    }

    $scope.listarRecibos(persona);

    $scope.imprimir = function(dato) {
        console.log("Dato");
        console.log(dato);
        $.blockUI({css: {backgroundColor: 'transparent', color: '#fff', border: 'none'}, message: '<img src="static/imagenes/ellipsis.gif" />'});
        if(dato.tipoMovimiento == "FACTURA") {
            ComprobantesService.imprimirFactura(dato.movto, persona).then(function(response) {
                $.unblockUI();
                var status = response.status;
                if (status == 200) {
                    UtilFactory.downloadFile(response, "Factura"+dato.movto+".pdf");
                } else {
                    toastr.info("Ha ocurrido un error, intentelo de nuevo");
                }
            }).catch(function(e) {
                console.log(e);
            });
        } else if (dato.tipoMovimiento == "RECIBO") {
            var digital = false;
			//dato.movto = 1041923;
			//dato.movto = 1015178;
            if(dato.digital == "SI") {
                digital = true;
            } /*else {
                ComprobantesService.imprimirRecibo(dato.movto, digital, persona, "C").then(function(response) {
                    $.unblockUI();
                    var status = response.status;
                    if (status == 200) {
                        UtilFactory.downloadFile(response, "Recibo"+dato.movto+".pdf");
                    } else {
                        toastr.info("Ha ocurrido un error, intentelo de nuevo");
                    }
                }).catch(function(e) {
                    console.log(e);
                });
            }
            */
            ComprobantesService.imprimirRecibo(dato.movto, digital, persona, "O").then(function(response) {
                $.unblockUI();
                var status = response.status;
                if (status == 200) {
                    UtilFactory.downloadFile(response, "Recibo"+dato.movto+".pdf");
                } else {
                    toastr.info("Ha ocurrido un error, intentelo de nuevo");
                }
            }).catch(function(e) {
                console.log(e);
            });
            
        }
    }
}]);

/*
var cabecera = {
    existeTasaVariable=N, 
    numeroDocto=724805, 
    codigoPersona=10019, 
    fondoAcumulado=5727130.00, 
    cobrosVarios=0, 
    origen=R, 
    doctoIdentidad= "C.I.: 1825318", 
    nroDoctoOrigen=58264, 
    imprimeDeclaracion=N, 
    tipoValor=OTROS, 
    telefono="Telef.: 021424585", 
    totalGs=1278660.00, 
    vueltoDolares=0.00, 
    apellidosYNombres="RIOS ACOSTA, ARNALDO BENITO", 
    cambio=1.00, 
    totalDl=0.00, 
    persona=10019, 
    cobrosDeNoAportes=1, 
    formaPagoDl=0.00, 
    categoria=1, 
    direccion="Direc.: ANDRES GILL 1381 CASI MALLORQUIN", 
    numeroFactura=10050042999, 
    fecha=2020-04-27, 
    fechaRecibo="2020-05-11 00:00:00.0", 
    //2=2, 
    vueltoGuaranies=0.00, 
    formaPagoGs=1127655.0000, 
    movto=989801, 
    formaPagoAux="Forma de Pago:  GS. 1.110.079 (OTROS)"
}

var detalle1 = [
    {
        descripcion=CREDITO, 
        cuota=17, 
        comision_cobrador=0.00, 
        fecha_vencimiento=2020-04-30, 
        intereses_punitorios=0.00, 
        intereses=151005.00, 
        monto=1127655.00, 
        impuesto=0.00,
        descuento_capital=0.00, 
        concepto=6, 
        moneda=GS, 
        numero_docto=13521, 
        total_descuentos=0.00, 
        cant_cuotas=24, 
        intereses_moratorios=0.00
    }
];

var detalle2 = [
    {
        descripcion=FAM, 
        max_fecha_vencimiento="2020-03-05 00:00:00.0", 
        monto=10000.00, 
        comision_cobrador=0.00, 
        min_fecha_vencimiento=2020-03-05, 
        concepto=3, 
        moneda=GS, 
        intereses_punitorios=0.00, 
        total_descuentos=0.00, 
        intereses=0.00, 
        intereses_moratorios=0.00
    }, 
    {
        descripcion=FAP, 
        max_fecha_vencimiento="2020-03-05 00:00:00.0", 
        monto=10000.00, 
        comision_cobrador=0.00, 
        min_fecha_vencimiento=2020-03-05, 
        concepto=4, 
        moneda=GS, 
        intereses_punitorios=0.00, 
        total_descuentos=0.00, 
        intereses=0.00, 
        intereses_moratorios=0.00
    }, 
    {
        descripcion="APORTE JUBILATORIO NORMAL", 
        max_fecha_vencimiento="2020-03-05 00:00:00.0", 
        monto=285000.00, 
        comision_cobrador=0.00, 
        min_fecha_vencimiento=2020-03-05, 
        concepto=1,
        moneda=GS, 
        intereses_punitorios=0.00, 
        total_descuentos=0.00, 
        intereses=0.00, 
        intereses_moratorios=0.00
    }
];

var pie = {
    credito=13521,
    moneda=GS, 
    clasificacion=1, 
    saldoTotalCredito=8950620.00, 
    proxVencimiento=2020-05-30, 
    saldoCapital=8409688.00
};


var detalle1 = [
    {
        descripcion=CREDITO, 
        cuota=17, 
        comision_cobrador=0.00, 
        fecha_vencimiento=2020-04-30, 
        intereses_punitorios=0.00, 
        intereses=151005.00, 
        monto=1127655.00, 
        impuesto=0.00, 
        descuento_capital=0.00, 
        concepto=6, 
        moneda=GS, 
        numero_docto=13521, 
        total_descuentos=0.00, 
        cant_cuotas=24, 
        intereses_moratorios=0.00
    }
];
*/